import { FC } from "react";
import { StackProps } from "@mui/material";
import { HeaderCellWidthDateTooltipProps } from "../../../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { azureReservationsCoverageDataTotalsSelector } from "../../../../../../../../../../store/commitments/selectors/azure/reservations/data/azureReservationsCoverageDataTotalsSelector";
import { ReservationsColumnsHeaderCellWithTotalAndDateCommon } from "../../../../../../../reservations/components/content/data-grid/components/cells/common/ReservationsColumnsHeaderCellWithTotalAndDateCommon";

interface AzureReservationsColumnsHeaderCellWithTotalAndDateProps
  extends HeaderCellWidthDateTooltipProps {
  type: "netSavings" | "coveredUsage" | "unCoveredUsage" | "coveredHours";
  alignItems?: StackProps["alignItems"];
}

export const AzureReservationsColumnsHeaderCellWithTotalAndDate: FC<
  AzureReservationsColumnsHeaderCellWithTotalAndDateProps
> = ({ ...props }) => {
  const totals = useAppSelector(azureReservationsCoverageDataTotalsSelector);

  return (
    <ReservationsColumnsHeaderCellWithTotalAndDateCommon
      {...props}
      totals={totals}
    />
  );
};
