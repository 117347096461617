import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResourceExplorerGroupingNullable } from "../../../../services/cloudchipr.api";
import {
  setResourceExplorerGrouping,
  setResourceExplorerSearch,
} from "../../resourceExplorerSlice";
import { setResourceExplorerPathThunk } from "../widgets/path/setResourceExplorerPathThunk";
import { getResourceExplorerDataThunk } from "../widgets/getResourceExplorerDataThunk";

export const resourceExplorerGroupByChangeThunk = createAsyncThunk(
  "resourceExplorer/resourceExplorerGroupByChange",
  async (
    {
      grouping,
      groupValues,
    }: {
      grouping: ResourceExplorerGroupingNullable;
      groupValues?: string[];
    },
    { dispatch },
  ) => {
    dispatch(setResourceExplorerGrouping({ grouping, groupValues }));
    dispatch(setResourceExplorerSearch(""));

    dispatch(setResourceExplorerPathThunk(false));
    dispatch(getResourceExplorerDataThunk());
  },
);
