import { Row } from "@tanstack/react-table";
import { Button, Stack, Typography } from "@mui/material";
import { ColumnSetupType } from "../../../../../../../../../storybook/data-grid/utils/types/types";
import { ProviderUniqueIdCell } from "../../../../../../common/components/data-grid/cells/ProviderUniqueIdCell";
import {
  commitmentsPaymentOptionLabels,
  commitmentsResourceTypeNames,
} from "../../../../../../common/utils/constants/labels";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { HeaderCellWidthDateTooltip } from "../../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import { UtilizationCell } from "../../../../../../reservations/components/content/data-grid/components/cells/UtilizationCell";
import { AzureCommitment } from "../../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../../utils/numeral/money";
import { labelsFilterFn } from "../../../../../../common/utils/helpers/grid/labelsFilterFn";
import { DatesCell } from "../../../../../../common/components/data-grid/cells/DatesCell";
import { CommitmentsTableName } from "../../../../../../common/components/data-grid/cells/CommitmentsTableName";
import { ScopeCell } from "../../../../../../common/components/data-grid/cells/ScopeCell";

const cellStyles = (row: Row<any>) => {
  return {
    bgcolor: row.original.state === "expires_soon" ? "#ed6c0216" : undefined,
  };
};

export const azureReservedInstancesTableColumns: ColumnSetupType<AzureCommitment>[] =
  [
    {
      size: 300,
      minSize: 150,
      accessorKey: "provider_unique_id",
      header: "Reservation Name",
      cell: ({ row }) => (
        <ProviderUniqueIdCell id={row.original.provider_unique_id} />
      ),
      meta: { cellStyles, hideFromSettings: true },
    },
    {
      minSize: 170,
      accessorKey: "type",
      header: "Type",
      cell: ({ row }) => {
        const type = row.original.type;

        return (
          <TypographyWithTooltip
            title={commitmentsResourceTypeNames[type] ?? null}
          />
        );
      },
      meta: { cellStyles },
    },
    {
      minSize: 180,
      accessorKey: "product_name",
      header: "Product Name",
      cell: ({ row }) => {
        return (
          <TypographyWithTooltip title={row.original.product_name ?? null} />
        );
      },
      meta: { cellStyles },
    },
    {
      minSize: 100,
      accessorKey: "instance_count",
      header: "Quantity",
      cell: ({ row }) => {
        return row.original.instance_count;
      },
      meta: { cellStyles },
    },
    {
      size: 200,
      minSize: 150,
      accessorKey: "scope",
      header: "Scope",
      cell: ({ row }) => {
        return <ScopeCell {...row.original.scope} />;
      },
      meta: { cellStyles },
    },
    {
      size: 220,
      minSize: 210,
      enableResizing: false,
      sortingFn: (currentRow, nextRow) => {
        const current = currentRow.original?.used_commitment_to_date?.cost ?? 0;
        const next = nextRow.original?.used_commitment_to_date?.cost ?? 0;

        return current - next;
      },
      accessorKey: "used_commitment_to_date",
      header: () => <HeaderCellWidthDateTooltip title="Utilization" />,
      cell: ({ row }) => {
        if (!row.original) {
          return <div>-</div>;
        }
        return <UtilizationCell reservation={row.original} hideHour />;
      },
      meta: { cellStyles, headerTitle: "Utilization" },
    },
    {
      size: 150,
      minSize: 100,
      accessorKey: "total_commitment_to_date",
      header: () => <HeaderCellWidthDateTooltip title="Reserved" />,
      meta: { cellStyles, headerTitle: "Reserved" },
      sortingFn: (currentRow, nextRow) => {
        const current =
          currentRow.original?.total_commitment_to_date?.cost ?? 0;
        const next = nextRow.original?.total_commitment_to_date?.cost ?? 0;

        return current - next;
      },
      cell: ({ row }) =>
        money(row.original.total_commitment_to_date?.cost) ?? "",
    },

    {
      size: 140,
      minSize: 110,
      accessorKey: "region",
      header: "Region/AZ",
      meta: { cellStyles },
    },
    {
      size: 160,
      accessorKey: "payment_option",
      header: "Payment Option",
      cell: ({ row }) => {
        const option = row.original.payment_option;

        return commitmentsPaymentOptionLabels[option] ?? "-";
      },
      meta: { cellStyles },
      filterFn: (row, _, filterValue) =>
        labelsFilterFn(
          row.original.payment_option,
          commitmentsPaymentOptionLabels,
          filterValue,
        ),
    },
    {
      size: 150,
      minSize: 110,
      accessorKey: "net_savings_to_date",
      header: () => (
        <Stack alignItems="end" flex={1}>
          <HeaderCellWidthDateTooltip title="Net Savings" />
        </Stack>
      ),
      cell: ({ row }) => (
        <Typography align="right" pr={4}>
          {money(row.original.net_savings_to_date)}
        </Typography>
      ),
      meta: { cellStyles, headerTitle: "Net Savings" },
    },
    {
      size: 140,
      minSize: 110,
      accessorKey: "start_date",
      header: "Start Date",
      cell: ({ row }) => <DatesCell date={row.original.start_date} />,
      meta: { cellStyles },
    },

    {
      size: 140,
      minSize: 110,
      accessorKey: "end_date",
      header: "End Date",
      cell: ({ row }) => (
        <DatesCell date={row.original.end_date} state={row.original.state} />
      ),
      meta: { cellStyles },
    },
    {
      enableSorting: false,
      enableResizing: false,
      accessorKey: "has_recommendation",
      header: "Recommendation",
      cell: ({ row }) => {
        const hasRecommendation = row.original.has_recommendation;

        if (hasRecommendation) {
          return (
            <Button
              size="small"
              variant="outlined"
              sx={{ textTransform: "initial" }}
            >
              <CommitmentsTableName
                id={row.original.provider_unique_id}
                type="commitment"
                name="Review"
              />
            </Button>
          );
        }

        return (
          <Typography variant="body2" color="text.disabled">
            No recommendation
          </Typography>
        );
      },
      meta: { cellStyles },
    },
  ];
