import { Typography } from "@mui/material";

import { CostBreakdownWidgetSetupTableLabelHeaderCell } from "./cells/CostBreakdownWidgetSetupTableLabelHeaderCell";
import { CostBreakdownWidgetSetupTableTotalCostHeaderCell } from "./cells/CostBreakdownWidgetSetupTableTotalCostHeaderCell";
import { CostBreakdownWidgetSetupTablePrevTotalCostHeaderCell } from "./cells/CostBreakdownWidgetSetupTablePrevTotalCostHeaderCell";
import { CostBreakdownWidgetSetupTableTrendHeaderCell } from "./cells/CostBreakdownWidgetSetupTableTrendHeaderCell";
import { CostBreakdownWidgetSetupTableForecastedCostHeaderCell } from "./cells/CostBreakdownWidgetSetupTableForecastedCostHeaderCell";
import { CostBreakdownWidgetSetupPreviousPeriodCell } from "./cells/CostBreakdownWidgetSetupPreviousPeriodCell";
import { CostBreakdownWidgetSetupTrendCell } from "./cells/CostBreakdownWidgetSetupTrendCell";
import {
  ForecastOption,
  NullableForecastedCost,
  ResourceExplorerGroupingNullable,
} from "../../../../../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { labelColumnSortingFn } from "../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/utils/helpers/sortingFunctions/sortingFunctions";
import { groupingsExcludingForecastedCostsColumns } from "../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/utils/constants/groupingsExcludingForecastedCostsColumns";
import { ResourceExplorerGridDataWithId } from "../../../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";
import { forecastedCostOptionLabels } from "../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/utils/constants";
import { CostBreakdownWidgetLabelCell } from "../../../../../../widgets/cost-breakdown/components/chart/table/cells/CostBreakdownWidgetLabelCell";
import { getForecastedCostsSortingFn } from "../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/utils/helpers/sortingFunctions/forecastedCostsSortingFn";

const getColumnsStartPart =
  (): ColumnSetupType<ResourceExplorerGridDataWithId>[] => {
    return [
      {
        size: 400,
        minSize: 150,
        accessorKey: "label",
        header: () => {
          return <CostBreakdownWidgetSetupTableLabelHeaderCell />;
        },
        sortingFn: labelColumnSortingFn,
        cell: CostBreakdownWidgetLabelCell,
        meta: {
          headerTitle: "grouping",
        },
      },
      {
        minSize: 110,
        accessorKey: "total_cost",
        header: () => <CostBreakdownWidgetSetupTableTotalCostHeaderCell />,
        cell: (cell) => {
          const val = cell.getValue() as number;

          return (
            <Typography variant="body2" align="right" pr={4}>
              {isNaN(val) ? "N / A" : money(val)}
            </Typography>
          );
        },
        meta: {
          headerTitle: "Total Cost",
        },
      },
      {
        minSize: 170,
        accessorKey: "previous_period_total_cost",
        header: () => <CostBreakdownWidgetSetupTablePrevTotalCostHeaderCell />,
        cell: (cell) => {
          const val = cell.getValue() as number | null;

          return <CostBreakdownWidgetSetupPreviousPeriodCell value={val} />;
        },
        meta: {
          headerTitle: "Previous Period Total Cost",
        },
      },
      {
        size: 100,
        accessorKey: "trend",
        header: () => <CostBreakdownWidgetSetupTableTrendHeaderCell />,
        cell: (cell) => {
          const data = cell.getValue() as number | null;

          return <CostBreakdownWidgetSetupTrendCell value={data} />;
        },
        meta: {
          headerTitle: "Trend",
        },
      },
    ];
  };

interface GetCostBreakdownWidgetTableViewColumnsArgs {
  widgetId?: string;
  forecastOption?: ForecastOption;
  grouping?: ResourceExplorerGroupingNullable;
}

export const getCostBreakdownWidgetSetupTableViewColumns = ({
  forecastOption,
  grouping,
}: GetCostBreakdownWidgetTableViewColumnsArgs): ColumnSetupType<ResourceExplorerGridDataWithId>[] => {
  const startPart = getColumnsStartPart();
  if (
    !grouping ||
    groupingsExcludingForecastedCostsColumns.has(grouping) ||
    !forecastOption
  ) {
    return startPart;
  }
  return [
    ...startPart,
    {
      minSize: 190,
      accessorKey:
        forecastOption === "month" ? "monthly_forecast" : "quarterly_forecast",
      header: () => <CostBreakdownWidgetSetupTableForecastedCostHeaderCell />,
      meta: {
        headerTitle: `Forecasted ${forecastedCostOptionLabels.get(
          "month",
        )} Cost`,
      },
      sortingFn: getForecastedCostsSortingFn(forecastOption),
      cell: (cell) => {
        const data: NullableForecastedCost = cell.getValue();

        return (
          <Typography variant="body2" align="right" pr={4}>
            {data === null || data === undefined
              ? "-"
              : `~${money(data?.cost)}`}
          </Typography>
        );
      },
    },
  ];
};
