import { ChangeEvent, FC, KeyboardEvent, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import { navigationHierarchyItemNoDndKey } from "../../../sortable-tree/utils/constants";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import {
  EmbeddedUser,
  ProtectionDetails,
} from "../../../../../../services/cloudchipr.api";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { updateResourceExplorerById } from "../../../../../../store/resource-explorer/thunks/common/updateResourceExplorerById";
import { updateResourceExplorerLoadingSelector } from "../../../../../../store/resource-explorer/selectors/loading/updateResourceExplorerLoadingSelector";
import { getPressEnterHandler } from "../../../../../utils/helpers/getPressEnterHandler";
import { protectTitlesByProtection } from "../utils/constants";
import { updateDashboardHierarchyItemThunk } from "../../../../../../store/dashboards/thunks/common/updateDashboardHierarchyItemThunk";
import { patchDashboardVisibilityHierarchyByDashboardIdLoadingSelector } from "../../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/patchDashboardVisibilityHierarchyByDashboardIdLoadingSelector";
import { getLabelByBillingSourceType } from "../../../../../utils/helpers/getLabelByBillingSourceType";

interface ProtectActionDialogProps {
  id: string;
  open: boolean;
  closeMenu(): void;
  protectedBy?: EmbeddedUser;
  source: "view" | "dashboard";
  protectionDetails?: ProtectionDetails;
}

export const ProtectActionDialog: FC<ProtectActionDialogProps> = ({
  open,
  closeMenu,
  id,
  source,
  protectedBy,
  protectionDetails,
}) => {
  const dispatch = useAppDispatch();
  const [protectionDescription, setProtectionDescription] = useState("");

  const isProtected = protectionDetails?.is_protected;
  const protectTitles = protectTitlesByProtection.get(!!isProtected);
  const { dashboardId: activeDashboardId } = useParams<{
    dashboardId: string;
  }>();
  const name = getLabelByBillingSourceType(source, true);

  const viewLoading = useAppSelector((state) =>
    updateResourceExplorerLoadingSelector(state, id),
  );
  const dashboardLoading = useAppSelector(
    patchDashboardVisibilityHierarchyByDashboardIdLoadingSelector,
  );

  const closeDialogHandler = useCallback(() => {
    closeMenu();
    setProtectionDescription("");
  }, [closeMenu]);

  const protectHandler = useCallback(async () => {
    if (source === "dashboard") {
      await dispatch(
        updateDashboardHierarchyItemThunk({
          dashboardId: id,
          body: {
            is_protected: !isProtected,
            protection_description: protectionDescription,
          },
          activeDashboardId,
        }),
      );
    } else {
      await dispatch(
        updateResourceExplorerById({
          viewId: id,
          body: {
            is_protected: !isProtected,
            protection_description: protectionDescription,
          },
        }),
      );
    }

    closeDialogHandler();
  }, [
    dispatch,
    isProtected,
    protectionDescription,
    id,
    closeDialogHandler,
    activeDashboardId,
    source,
  ]);

  const inputChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setProtectionDescription(event.target.value);
    },
    [],
  );
  const handlePressEnter = getPressEnterHandler(
    protectHandler,
    viewLoading || dashboardLoading,
  );

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={closeDialogHandler}
      onKeyDown={handlePressEnter}
      {...navigationHierarchyItemNoDndKey}
    >
      <DialogTitleClosable
        title={`${protectTitles?.title} ${name}`}
        onClose={closeDialogHandler}
      />

      <DialogContent dividers>
        <Stack spacing={2}>
          <Typography variant="body1">{protectTitles?.contentTitle}</Typography>
          {isProtected ? (
            <Stack
              p={1}
              border={1}
              spacing={1}
              borderRadius={1}
              bgcolor="grey.100"
              alignItems="center"
              borderColor="grey.200"
              justifyContent="center"
            >
              <Typography variant="body2" color="text.secondary">
                Protected by{" "}
                <Typography
                  component="span"
                  variant="inherit"
                  color="text.primary"
                >
                  {protectedBy?.name ?? protectedBy?.email}
                </Typography>{" "}
              </Typography>
              {protectionDetails?.description && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight="medium"
                >
                  {protectionDetails.description}
                </Typography>
              )}
            </Stack>
          ) : (
            <TextField
              value={protectionDescription}
              size="small"
              onChange={inputChangeHandler}
              label="Description (Optional)"
              onKeyDown={stopPropagation}
            />
          )}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3 }}>
        <Button color="tertiary" onClick={closeDialogHandler}>
          Cancel
        </Button>
        <LoadingButton
          onClick={protectHandler}
          loading={viewLoading || dashboardLoading}
          variant="contained"
        >
          {protectTitles?.title} {name}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const stopPropagation = (event: KeyboardEvent<HTMLInputElement>) => {
  event.stopPropagation();
};
