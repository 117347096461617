import { FC } from "react";
import { useLocation } from "react-router-dom";
import { IconButton, PopoverOrigin } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import { useAppSelector } from "../../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { useMenuHook } from "../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../services/permissions";
import { AppNavItemWrapper } from "../../AppNavItemWrapper";
import { ResourceExplorerV2NavigationAddActionMenu } from "../../../../navigation/components/resource-explorer-v2/components/ResourceExplorerV2NavigationAddActionMenu";
import { ResourceExplorerV2Visibility } from "../../../../navigation/components/resource-explorer-v2/components/visibility-types/ResourceExplorerV2Visibility";

export const BillingExplorerNavigation: FC = () => {
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  const { can } = useAppAbility();
  const canCreateView = can("create", "resource-explorer");

  const location = useLocation();
  const resourceExplorerSelected =
    location.pathname.startsWith("/resource-explorer");

  return (
    <AppNavItemWrapper
      divider
      primary="Billing Explorer"
      to="/resource-explorer"
      Icon={BarChartOutlinedIcon}
      selected={resourceExplorerSelected}
      navCollapsed={navigationCollapsed}
      secondaryAction={
        <div>
          <IconButton size="small" onClick={openMenu} disabled={!canCreateView}>
            <AddIcon fontSize="inherit" />
          </IconButton>

          <ResourceExplorerV2NavigationAddActionMenu
            open={open}
            onCloseMenu={closeMenu}
            anchorEl={anchor}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
          />
        </div>
      }
    >
      <ResourceExplorerV2Visibility key="resource-explorer-hierarchy" />
    </AppNavItemWrapper>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
