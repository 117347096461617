import { FC } from "react";
import Typography from "@mui/material/Typography";
import { ExampleTypography } from "./ExampleTypography";
import { useAppSelector } from "../../../../../../../store/hooks";
import { reportsDataPropertyByKeySelector } from "../../../../../../../store/reports/selector/reportsDataPropertyByKeySelector";
import { getLabelByBillingSourceType } from "../../../../../../utils/helpers/getLabelByBillingSourceType";
import { reportsOpenDrawerSelector } from "../../../../../../../store/reports/selector/reportsOpenDrawerSelector";

export const ReportsCustomMessageSubject: FC = () => {
  const source = useAppSelector(
    reportsDataPropertyByKeySelector("source_type"),
  );
  const isSubscriptionMessage = useAppSelector(reportsOpenDrawerSelector);

  const sourceLabel = getLabelByBillingSourceType(source);
  const fullSourceLabel =
    source === "view" ? `billing ${sourceLabel}` : sourceLabel;

  if (isSubscriptionMessage) {
    return (
      <Typography variant="body1">
        Subject:{" "}
        <ExampleTypography>{`${fullSourceLabel} name`}</ExampleTypography>{" "}
        {sourceLabel} <ExampleTypography>frequency</ExampleTypography> update
      </Typography>
    );
  }

  return (
    <Typography variant="body1">
      Subject:{" "}
      <ExampleTypography>{`${fullSourceLabel} name`}</ExampleTypography>{" "}
      {sourceLabel} for <ExampleTypography>date</ExampleTypography>
    </Typography>
  );
};
