import { RootState } from "../../../../../store";
import { AzureCommitment } from "../../../../../../services/cloudchipr.api";
import { azureReservationsDataForTableSelector } from "../azureReservationsDataForTableSelector";

export const azureReservationsDataSelector = (
  state: RootState,
): AzureCommitment[] | undefined => {
  const data = azureReservationsDataForTableSelector(state);

  return data?.utilization_and_coverage.commitments;
};
