import { resourceExplorerPossibleGroupingsSelector } from "./resourceExplorerPossibleGroupingsSelector";
import { generateResourceExplorerPossibleGroupingsFormatedData } from "./utils/helpers";
import { RootState } from "../../../store";
import { ResourceExplorerFilterProvider } from "../../../../services/cloudchipr.api";

import { ResourceExplorerPossibleGroupingsFormattedData } from "../../../../components/pages/common/resource-explorer-grouping-selector-v2/utils/types";

export type GroupingProviderMap = Record<
  string,
  ResourceExplorerFilterProvider[]
>;

export const resourceExplorerPossibleGroupingsFormattedDataSelector = (
  state: RootState,
): ResourceExplorerPossibleGroupingsFormattedData | undefined => {
  const data = resourceExplorerPossibleGroupingsSelector(state);

  if (!data) {
    return;
  }

  return generateResourceExplorerPossibleGroupingsFormatedData(data);
};
