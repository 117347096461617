import {
  ResourceExplorerFilterProvider,
  ResourceExplorerGroupingNullable,
} from "../../../../../../services/cloudchipr.api";
import { getAzureTagsFeatureToggleFromStorage } from "../../../../resource-explorer/utils/helpers/azure-tags/getAzureTagsFeatureToggleFromStorage";

const providersByGrouping = new Map<
  ResourceExplorerGroupingNullable,
  ResourceExplorerFilterProvider[]
>([
  ["region", ["aws", "gcp", "azure"]],
  ["resource", ["aws", "gcp", "azure", "confluent"]],
  ["cost_allocation_tag", ["aws", "gcp", "mongo"]],
  ["cost_allocation_tag_value", ["aws", "gcp", "mongo"]],
  ["charge_type", ["aws", "gcp", "azure"]],
  ["instance_family", ["aws"]],
  ["instance_type", ["aws"]],
  ["environment", ["confluent"]],
  ["marketplace_service", ["aws", "azure"]],
  ["usage_type", ["aws"]],
  ["description", ["aws"]],
  ["pricing_term", ["aws"]],
  ["cluster", ["mongo", "eks"]],
  ["resource_group", ["azure"]],
]);

export const availableCloudProvidersByGrouping = (
  grouping: ResourceExplorerGroupingNullable,
): ResourceExplorerFilterProvider[] | undefined => {
  const enableAzureBillingExportProcessing =
    getAzureTagsFeatureToggleFromStorage();

  const providers = providersByGrouping.get(grouping);

  const needToShowAzureForTags =
    enableAzureBillingExportProcessing &&
    providers &&
    grouping?.includes("tag");

  if (needToShowAzureForTags) {
    return [...providers, "azure"];
  }

  return providers;
};
