import { FC, useMemo } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import Stack from "@mui/material/Stack";
import { IconButton, Link, Tooltip } from "@mui/material";
import { PlayCircleOutlined } from "@mui/icons-material";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import { CopyIconButton } from "../../../../common/CopyIconButton";
import { useHover } from "../../../../../utils/hooks/useHover.hook";
import {
  resourceIdSearchParam,
  resourceTypeSearchParam,
} from "../../../live-usage-mgmt/utils/constants/searchParams";
import { snakeToUpperCaseWithSpaces } from "../../../../../utils/helpers/snakeCaseToCapitalizedWithSpaces";
import { getParentResourceType } from "../../../../../utils/helpers/resources/getParentResourceType";

export const SavingsOpportunityActionTypeCellRenderer: FC<
  CustomCellRendererProps<SavingsOpportunity>
> = (cell) => {
  const { ref, hovered } = useHover();

  const data = cell.data;
  const actionType =
    data?.action_type === "off_hours"
      ? `create_off_hour`
      : (data?.action_type ?? "");
  const title = snakeToUpperCaseWithSpaces(actionType);
  const parentResourceType = useMemo(
    () => (data?.service ? getParentResourceType(data?.service) : ""),
    [data?.service],
  );
  const resourceType =
    parentResourceType !== data?.service ? parentResourceType : data?.service;
  const resourceIdParam =
    parentResourceType !== data?.service
      ? ""
      : `&${resourceIdSearchParam}=${data.resource_id}`;

  if (!data) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center" ref={ref}>
      <TypographyWithTooltip title={title} variant="body2" />

      <CopyIconButton
        data={title}
        visible={hovered}
        TooltipProps={{ placement: "top" }}
      />

      {data.action_type === "live_filtered_resource" && hovered && (
        <Tooltip title="View in Live Usage" arrow placement="top">
          <IconButton
            size="small"
            component={Link}
            href={`/${data.account.provider}/live-usage/?accountIds=${data.account.id}&${resourceTypeSearchParam}=${resourceType}${resourceIdParam}`}
            target="_blank"
          >
            <PlayCircleOutlined fontSize="small" color="action" />
          </IconButton>
        </Tooltip>
      )}

      {data.action_type === "off_hours" && hovered && (
        <Tooltip title="View in Off Hours page" arrow placement="top">
          <IconButton
            size="small"
            component={Link}
            href={`/recommendations/off-hours/${data.resource_type}?id=${data.recommendation_id}`}
            target="_blank"
          >
            <HistoryToggleOffOutlinedIcon fontSize="small" color="action" />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};
