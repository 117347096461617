import {
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
} from "../../../../../../services/cloudchipr.api";

export const filterTypeWithAsyncSearch =
  new Set<ResourceExplorerDynamicFilterItemType>([
    "resource_id",
    "resource_group",
  ]);

export const includeAllExcludedProvider: ResourceExplorerFilterProvider[] = [
  "dimensions",
  "eks",
  "global",
];
