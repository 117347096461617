import { ListItemText, Stack } from "@mui/material";
import { GroupingOptionIcon } from "./GroupingOptionIcon";
import {
  ResourceExplorerGrouping,
  ResourceExplorerPossibleGrouping,
} from "../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";

interface GetGroupingOptionsWithParentLabelArgs {
  parentLabel: string;
  children: ResourceExplorerPossibleGrouping[];
}
export const getResourceExplorerGroupingOptionsWithParentLabel = ({
  parentLabel,
  children,
}: GetGroupingOptionsWithParentLabelArgs) => {
  return {
    key: parentLabel,
    value: parentLabel,
    disableSelection: true,
    label: (
      <Stack direction="row" spacing={1} alignItems="center">
        <GroupingOptionIcon
          grouping={children[0].key as ResourceExplorerGrouping}
        />
        <ListItemText primary={parentLabel} />
      </Stack>
    ),
    rawValue: { search: parentLabel },
    nestedOptions: children.map((option) => ({
      value: option?.key ?? "",
      rawValue: { search: `${option.label?.singular} ${parentLabel}` },
      label: (
        <Stack direction="row" spacing={1} alignItems="center" pl={2}>
          {option?.key && (
            <GroupingOptionIcon
              grouping={option.key as ResourceExplorerGrouping}
            />
          )}{" "}
          <TypographyWithTooltip
            variant="body2"
            title={option.label?.singular ?? ""}
          />
        </Stack>
      ),
    })),
  };
};
