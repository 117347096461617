import { FC, useCallback } from "react";
import { TablePagination } from "@mui/material";
import { setExecutionLogPagination } from "../../../../../store/execution-log/executionLogSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { executionLogDataSelector } from "../../../../../store/execution-log/selectors/executionLogDataSelector";
import { executionLogPaginationSize } from "../../utils/constants/common";
import { getExecutionLogThunk } from "../../../../../store/execution-log/thunks/getExecutionLogThunk";
import { executionLogDataLoadingSelector } from "../../../../../store/execution-log/selectors/executionLogDataLoadingSelector";

export const ExecutionLogsDataGridPagination: FC = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(executionLogDataSelector);
  const loading = useAppSelector(executionLogDataLoadingSelector);

  const pageChangeHandler = useCallback(
    (_: any, newPage: any) => {
      dispatch(setExecutionLogPagination({ page: newPage }));
      dispatch(
        getExecutionLogThunk({
          withoutDetails: true,
        }),
      );
    },
    [dispatch],
  );

  return (
    <TablePagination
      component="div"
      count={data?.total_items ?? 0}
      page={data?.current_page ?? 0}
      rowsPerPageOptions={[]}
      disabled={loading}
      sx={{ overflow: "hidden", minHeight: 52 }}
      onPageChange={pageChangeHandler}
      rowsPerPage={executionLogPaginationSize}
    />
  );
};
