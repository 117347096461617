import { FC, useCallback, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  NavItem,
  NavItemProps,
} from "../../navigation/components/nav-item/NavItem";
import { useAppNavOverlayContext } from "../utils/useAppNavOverlay.context";

export const AppNavItem: FC<NavItemProps> = ({
  onButtonClick,
  to,
  ...props
}) => {
  const { onClose } = useAppNavOverlayContext();
  const navigate = useNavigate();

  const handleButtonClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      onClose?.();

      if (to) {
        navigate(to);
      } else {
        onButtonClick?.(event);
      }
    },
    [onClose, to, onButtonClick, navigate],
  );

  return (
    <NavItem to={undefined} onButtonClick={handleButtonClick} {...props} />
  );
};
