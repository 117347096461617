import { FC, ReactNode } from "react";
import BrowserNotSupportedIcon from "@mui/icons-material/BrowserNotSupported";
import MiscellaneousServicesOutlinedIcon from "@mui/icons-material/MiscellaneousServicesOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import LoyaltyOutlinedIcon from "@mui/icons-material/LoyaltyOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import AltRouteOutlinedIcon from "@mui/icons-material/AltRouteOutlined";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import { ResourceExplorerGroupingNullable } from "../../../../../services/cloudchipr.api";
import UsageTypeIcon from "../../../../../assets/images/logos/re-groupings/UsageTypeIcon";
import PricingTermIcon from "../../../../../assets/images/logos/re-groupings/PricingTermIcon";
import ItemDescriptionIcon from "../../../../../assets/images/logos/re-groupings/ItemDescriptionIcon";
import K8SClusterIcon from "../../../../../assets/images/logos/re-groupings/K8SClusterIcon";
import MongoSvgIcon from "../../../../../assets/images/logos/providers/MongoSvgIcon";
import EKSSvgIcon from "../../../../../assets/images/logos/providers/EKSSvgIcon";

interface GroupingOptionIconProps {
  grouping: ResourceExplorerGroupingNullable;
}

const iconByGrouping: Record<
  Exclude<ResourceExplorerGroupingNullable, null>,
  ReactNode
> = {
  none: <BrowserNotSupportedIcon fontSize="small" color="action" />,
  account: <LanOutlinedIcon fontSize="small" color="action" />,
  service: (
    <MiscellaneousServicesOutlinedIcon fontSize="small" color="action" />
  ),
  region: <PinDropOutlinedIcon fontSize="small" color="action" />,
  resource: <DnsOutlinedIcon fontSize="small" color="action" />,
  product_family: <BubbleChartOutlinedIcon fontSize="small" color="action" />,
  cloud_provider: <CloudOutlinedIcon fontSize="small" color="action" />,
  tag_key: <DiscountOutlinedIcon fontSize="small" color="action" />,
  cost_allocation_tag: <DiscountOutlinedIcon fontSize="small" color="action" />,
  cost_allocation_tag_value: (
    <LoyaltyOutlinedIcon fontSize="small" color="action" />
  ),
  tag_value: <LoyaltyOutlinedIcon fontSize="small" color="action" />,
  charge_type: <ReceiptLongOutlinedIcon fontSize="small" color="action" />,
  instance_family: <CategoryOutlinedIcon fontSize="small" color="action" />,
  instance_type: <HubOutlinedIcon fontSize="small" color="action" />,
  environment: <AltRouteOutlinedIcon fontSize="small" color="action" />,
  marketplace_service: (
    <LocalGroceryStoreOutlinedIcon fontSize="small" color="action" />
  ),
  category: <ListOutlinedIcon fontSize="small" color="action" />,
  cluster: <K8SClusterIcon fontSize="small" />,
  resource_group: <ViewInArIcon fontSize="small" color="action" />,

  parent_resource_id: <EKSSvgIcon fontSize="small" />,
  type: <EKSSvgIcon fontSize="small" />,
  deployment: <EKSSvgIcon fontSize="small" />,
  workload_name: <EKSSvgIcon fontSize="small" />,
  workload_type: <EKSSvgIcon fontSize="small" />,
  namespace: <EKSSvgIcon fontSize="small" />,
  node: <EKSSvgIcon fontSize="small" />,

  usage_type: <UsageTypeIcon fontSize="small" color="action" />,
  pricing_term: <PricingTermIcon fontSize="small" color="action" />,
  description: <ItemDescriptionIcon fontSize="small" color="action" />,

  group: <MongoSvgIcon fontSize="small" filter="grayscale(1)" />,
  organisation: <MongoSvgIcon fontSize="small" filter="grayscale(1)" />,

  credit_type: null,
};

export const GroupingOptionIcon: FC<GroupingOptionIconProps> = ({
  grouping,
}) => {
  if (!grouping) {
    return null;
  }

  return iconByGrouping[grouping];
};
