import { FC, Fragment } from "react";
import { capitalize, Typography } from "@mui/material";
import { ExampleTypography } from "./ExampleTypography";
import { useAppSelector } from "../../../../../../../store/hooks";
import { reportsDataPropertyByKeySelector } from "../../../../../../../store/reports/selector/reportsDataPropertyByKeySelector";
import { getLabelByBillingSourceType } from "../../../../../../utils/helpers/getLabelByBillingSourceType";
import { reportsOpenDrawerSelector } from "../../../../../../../store/reports/selector/reportsOpenDrawerSelector";

export const ReportsCustomMessageBody: FC = () => {
  const source = useAppSelector(
    reportsDataPropertyByKeySelector("source_type"),
  );
  const isSubscriptionMessage = useAppSelector(reportsOpenDrawerSelector);

  const fullSourceLabel =
    source === "view"
      ? `billing ${getLabelByBillingSourceType(source)}`
      : getLabelByBillingSourceType(source);

  if (isSubscriptionMessage) {
    return (
      <Fragment>
        <Typography variant="body1" lineHeight={1.5}>
          Please find the <ExampleTypography>frequency</ExampleTypography>{" "}
          update attached for{" "}
          <ExampleTypography>{`${fullSourceLabel} name`}</ExampleTypography>{" "}
          {fullSourceLabel}.
        </Typography>
        <Typography variant="body1" lineHeight={1.5}>
          Subscription -{" "}
          <ExampleTypography>subscription name</ExampleTypography>
        </Typography>
        <Typography variant="body1" lineHeight={1.5}>
          {capitalize(fullSourceLabel)} -{" "}
          <ExampleTypography>{`${fullSourceLabel} name`}</ExampleTypography>
        </Typography>
        <Typography variant="body1" lineHeight={1.5}>
          Subscription created by -{" "}
          <ExampleTypography>user name</ExampleTypography>
        </Typography>
      </Fragment>
    );
  }

  return (
    <Typography variant="body1" lineHeight={1.5}>
      Please find the attached report, generated from the{" "}
      <ExampleTypography>{`${fullSourceLabel} name`}</ExampleTypography>{" "}
      {fullSourceLabel} and sent by{" "}
      <ExampleTypography>user name</ExampleTypography> via Cloudchipr.
    </Typography>
  );
};
