import {
  ColDef,
  GetQuickFilterTextParams,
  ICellRendererParams,
} from "ag-grid-community";
import { Typography } from "@mui/material";
import { budgetsThresholdSortingFn } from "./sorting-functions/budgetsThresholdSortingFn";
import { budgetsCreatedBySortingFn } from "./sorting-functions/budgetsCreatedBySortingFn";
import { budgetsLastModifiedBySortingFn } from "./sorting-functions/budgetsLastModifiedBySortingFn";
import { budgetsCreatedByQuickFilter } from "./quick-filters/budgetsCreatedByQuickFilter";
import { budgetsLastModifiedByQuickFilter } from "./quick-filters/budgetsLastModifiedByQuickFilter";
import { Budget } from "../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../utils/numeral/money";
import { BudgetsPeriodCell } from "../../components/cells/BudgetsPeriodCell";
import { BudgetsProgressCell } from "../../components/cells/BudgetsProgressCell";
import { BudgetListActionsCell } from "../../components/cells/BudgetListActionsCell";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { BudgetsNameCell } from "../../components/cells/BudgetsNameCell";
import { BudgetsViewCell } from "../../components/cells/BudgetsViewCell";
import { BudgetsCreatedByCell } from "../../components/cells/BudgetsCreatedByCell";
import { BudgetsLastModifiedByCell } from "../../components/cells/BudgetsLastModifiedByCell";

export const budgetsGridColumns: ColDef[] = [
  {
    minWidth: 300,
    flex: 1,
    field: "name",
    headerName: "Name",
    pinned: "left",
    suppressColumnsToolPanel: true,
    getQuickFilterText: (params: GetQuickFilterTextParams<Budget>) =>
      params.data?.name,
    cellRenderer: BudgetsNameCell,
  },
  {
    minWidth: 200,
    flex: 1,
    field: "amount",
    headerName: "Budget",
    getQuickFilterText: (params: GetQuickFilterTextParams<Budget>) =>
      money(params.data?.amount),
    cellRenderer: ({ data: budget }: ICellRendererParams<Budget>) => {
      return (
        <Typography variant="body2" fontWeight="medium">
          {money(budget?.amount)}
        </Typography>
      );
    },
  },
  {
    minWidth: 250,
    flex: 1,
    field: "view.name",
    headerName: "Billing Report",
    getQuickFilterText: (params: GetQuickFilterTextParams<Budget>) =>
      params.data?.view.name,
    cellRenderer: BudgetsViewCell,
  },
  {
    minWidth: 200,
    flex: 1,
    field: "threshold",
    headerName: "Threshold",
    getQuickFilterText: (params: GetQuickFilterTextParams<Budget>) =>
      params.data?.thresholds.map(({ percent }) => `${percent}%`).join(", "),
    comparator: budgetsThresholdSortingFn,
    cellRenderer: ({ data: budget }: ICellRendererParams<Budget>) => {
      return (
        <Typography variant="body2">
          {budget?.thresholds.map(({ percent }) => `${percent}%`).join(", ")}
        </Typography>
      );
    },
  },
  {
    minWidth: 200,
    flex: 1,
    field: "period",
    headerName: "Period",
    getQuickFilterText: (params: GetQuickFilterTextParams<Budget>) =>
      params.data?.period,
    cellRenderer: BudgetsPeriodCell,
  },
  {
    minWidth: 200,
    flex: 1,
    field: "progress",
    headerName: "Progress",
    getQuickFilterText: (params: GetQuickFilterTextParams<Budget>) =>
      `${params.data?.progress}%`,
    cellRenderer: BudgetsProgressCell,
  },
  {
    field: "created_by",
    headerName: "Created by",
    minWidth: 200,
    flex: 1,
    getQuickFilterText: budgetsCreatedByQuickFilter,
    comparator: budgetsCreatedBySortingFn,
    cellRenderer: BudgetsCreatedByCell,
  },
  {
    field: "modified_by",
    headerName: "Last Modified By",
    minWidth: 200,
    flex: 1,
    getQuickFilterText: budgetsLastModifiedByQuickFilter,
    comparator: budgetsLastModifiedBySortingFn,
    cellRenderer: BudgetsLastModifiedByCell,
  },
  agGridEmptyColumnToFitEmptySpace,
  {
    width: 70,
    minWidth: 70,
    pinned: "right",
    resizable: false,
    unSortIcon: false,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    cellRenderer: BudgetListActionsCell,
  },
];
