import { FC } from "react";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import { useLocation } from "react-router-dom";
import { LiveUsageNavigationItem } from "../../../app-navigation/components/page-components/live-usage/LiveUsageNavigationItem";
import { NavItem } from "../nav-item/NavItem";
import { useAppSelector } from "../../../../store/hooks";
import { liveUsageNavigationItemsSelector } from "../../../../store/accounts/selectors/liveUsageNavigationItemsSelector";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";

export const LiveUsageNavigation: FC = () => {
  const allAccountsGroupedByProviderAndOrg = useAppSelector(
    liveUsageNavigationItemsSelector,
  );
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);
  const location = useLocation();
  const selected = ["/aws", "/gcp", "/azure"].some((item) =>
    location.pathname.startsWith(item),
  );

  return (
    <NavItem
      primary="Live Resources"
      icon={PlayCircleOutlineOutlinedIcon}
      navCollapsed={navigationCollapsed}
      selected={selected}
    >
      {allAccountsGroupedByProviderAndOrg.map((props) => (
        <LiveUsageNavigationItem
          {...props}
          key={props.orgId + props.provider + props.accountIdsQueryParam}
        />
      ))}
    </NavItem>
  );
};
