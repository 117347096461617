import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CommitmentsInterface,
  UtilizationAndCoverageChartType,
  DataViewTab,
  UtilizationAndCoverageDetailsDrawer,
  CommitmentsRecommendationsFilters,
  CommitmentsTabsTypes,
} from "./utils/types/types";
import {
  DateDataPoint,
  Dates,
  ProviderType,
  SavingsPlanAccountScope,
  SavingsPlanCoverageGrouping,
} from "../../services/cloudchipr.api";
import { drawerDataGridPossibleGroupings } from "../../components/pages/commtiments/common/utils/constants/drawerDataGridPossibleGroupings";

const initialState: CommitmentsInterface = {
  currentOrgId: "",
  currentOrgProvider: null,
  commitmentsTypeTab: null,

  chartType: "area",
  coverageGrouping: "resource_type",
  dateGranularity: "daily",
  dates: {
    type: "past",
    unit: "day",
    value: 60,
    from: null,
    to: null,
    label: null,
  },
  dataViewTab: "commitment",
  detailsDrawer: {
    dates: { label: "past_thirty_days" },
    dateGranularity: "daily",
    coverageGrouping: "resource_type",
  },
  recommendationsFilters: {
    account_ids: [],
    term_in_years: ["one_year"],
    account_scope: "payer",
    type: ["ec2_instance"],
    look_back_period: ["thirty_days"],
    payment_option: ["no_upfront"],
  },
};

export const commitmentsSlice = createSlice({
  name: "commitments",
  initialState: initialState,
  reducers: {
    setCommitmentsOrgId: (state, action: PayloadAction<string>) => {
      state.currentOrgId = action.payload;
    },
    setCommitmentsOrgProvider: (state, action: PayloadAction<ProviderType>) => {
      state.currentOrgProvider = action.payload;
    },
    setCommitmentsTypeTab: (
      state,
      action: PayloadAction<CommitmentsTabsTypes>,
    ) => {
      state.commitmentsTypeTab = action.payload;
    },

    setCommitmentsDates: (state, action: PayloadAction<Dates>) => {
      state.dates = action.payload;
    },

    setCommitmentsDateGranularity: (
      state,
      action: PayloadAction<DateDataPoint>,
    ) => {
      state.dateGranularity = action.payload;
    },
    setCommitmentsChartType: (
      state,
      action: PayloadAction<UtilizationAndCoverageChartType>,
    ) => {
      state.chartType = action.payload;
    },

    setCommitmentsCoverageGrouping: (
      state,
      action: PayloadAction<SavingsPlanCoverageGrouping>,
    ) => {
      state.coverageGrouping = action.payload;
    },

    setCommitmentsDataViewTab: (state, action: PayloadAction<DataViewTab>) => {
      state.dataViewTab = action.payload;
    },

    //  drawer
    setCommitmentsDetailsDrawerDateGranularity: (
      state,
      action: PayloadAction<DateDataPoint>,
    ) => {
      state.detailsDrawer.dateGranularity = action.payload;
    },

    setCommitmentsDetailsDrawerDates: (state, action: PayloadAction<Dates>) => {
      state.detailsDrawer.dates = action.payload;
    },

    setCommitmentsDetailsDrawerCoverageGrouping: (
      state,
      action: PayloadAction<SavingsPlanCoverageGrouping>,
    ) => {
      state.detailsDrawer.coverageGrouping = action.payload;
    },

    setCommitmentsDetailsDrawerTarget: (
      state,
      action: PayloadAction<
        | {
            id: UtilizationAndCoverageDetailsDrawer["targetId"];
            type: UtilizationAndCoverageDetailsDrawer["targetType"];
          }
        | undefined
      >,
    ) => {
      const id = action.payload?.id;
      const type = action.payload?.type;

      state.detailsDrawer.targetId = id;
      state.detailsDrawer.targetType = type;

      if (type) {
        const grouping = drawerDataGridPossibleGroupings[type]?.at(0)?.value;

        if (grouping) {
          state.detailsDrawer.coverageGrouping = grouping;
        }
      }
    },

    // recommendations
    setCommitmentsRecommendationsAccountIds: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.recommendationsFilters.account_ids = action.payload;
    },

    setCommitmentsRecommendationsTerm: (
      state,
      action: PayloadAction<CommitmentsRecommendationsFilters["term_in_years"]>,
    ) => {
      state.recommendationsFilters.term_in_years = action.payload;
    },

    setCommitmentsRecommendationsAccountScope: (
      state,
      action: PayloadAction<SavingsPlanAccountScope>,
    ) => {
      state.recommendationsFilters.account_scope = action.payload;
    },

    setCommitmentsRecommendationsSavingsPlanType: (
      state,
      action: PayloadAction<CommitmentsRecommendationsFilters["type"]>,
    ) => {
      state.recommendationsFilters.type = action.payload;
    },

    setCommitmentsRecommendationsLockBackPeriod: (
      state,
      action: PayloadAction<
        CommitmentsRecommendationsFilters["look_back_period"]
      >,
    ) => {
      state.recommendationsFilters.look_back_period = action.payload;
    },

    setCommitmentsRecommendationsPaymentOption: (
      state,
      action: PayloadAction<
        CommitmentsRecommendationsFilters["payment_option"]
      >,
    ) => {
      state.recommendationsFilters.payment_option = action.payload;
    },

    setCommitmentsRecommendationsFilters: (
      state,
      action: PayloadAction<CommitmentsRecommendationsFilters | null>,
    ) => {
      if (!action.payload) {
        state.recommendationsFilters = initialState.recommendationsFilters;
      } else {
        state.recommendationsFilters = action.payload;
      }
    },
  },
});

export const {
  setCommitmentsDates,
  setCommitmentsOrgId,
  setCommitmentsOrgProvider,
  setCommitmentsTypeTab,
  setCommitmentsChartType,
  setCommitmentsDataViewTab,
  setCommitmentsDateGranularity,
  setCommitmentsCoverageGrouping,

  //  drawer
  setCommitmentsDetailsDrawerDates,
  setCommitmentsDetailsDrawerTarget,
  setCommitmentsDetailsDrawerDateGranularity,
  setCommitmentsDetailsDrawerCoverageGrouping,

  // recommendations
  setCommitmentsRecommendationsTerm,
  setCommitmentsRecommendationsFilters,
  setCommitmentsRecommendationsAccountIds,
  setCommitmentsRecommendationsAccountScope,
  setCommitmentsRecommendationsPaymentOption,
  setCommitmentsRecommendationsLockBackPeriod,
  setCommitmentsRecommendationsSavingsPlanType,
} = commitmentsSlice.actions;

export default commitmentsSlice.reducer;
