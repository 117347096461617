import { FC } from "react";
import { TabPanel } from "@mui/lab";
import { Stack } from "@mui/material";
import { AzureReservations } from "./reservations/AzureReservations";

export const AzureCommitments: FC = () => {
  return (
    <Stack p={1}>
      <TabPanel value="reservation" sx={{ p: 0 }}>
        <AzureReservations />
      </TabPanel>
    </Stack>
  );
};
