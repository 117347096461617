import { FC, useCallback } from "react";
import { PopoverProps, Stack } from "@mui/material";
import { GroupingByTagValuesHeader } from "./GroupingByTagValuesHeader";
import { NestedGroupingOptionTrigger } from "./triggers/NestedGroupingOptionTrigger";
import {
  ProviderType,
  ResourceExplorerGroupingNullable,
} from "../../../../../services/cloudchipr.api";
import { DropdownSelect } from "../../../../common/select/dropdown-select/DropdownSelect";
import { arrayIsEqual } from "../../../../../utils/arrayIsEqual";
import { DropdownHeaderComponent } from "../../../../common/select/dropdown-select/utils/types/types";
import { FilterTriggerComponentProps } from "../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { useAppSelector } from "../../../../../store/hooks";
import { resourceExplorerGroupingSelector } from "../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import { ProviderIcon } from "../../../../common/ProviderIcon";
import { resourceExplorerGroupingTagKeysLoadingSelector } from "../../../../../store/common/selectors/resource-explorer/resourceExplorerGroupingTagKeysLoadingSelector";
import { resourceExplorerGroupingTagKeysDataSelector } from "../../../../../store/common/selectors/resource-explorer/resourceExplorerGroupingTagKeysDataSelector";

interface GroupingByTagValueOptionProps {
  values?: string[];
  label: string;
  onClose(): void;
  onChange(
    grouping: ResourceExplorerGroupingNullable,
    groupValue?: string[],
  ): void;
}

export const GroupingByTagValueOption: FC<GroupingByTagValueOptionProps> = ({
  values,
  onChange,
  label,
  onClose,
}) => {
  const options = useAppSelector(resourceExplorerGroupingTagKeysDataSelector);
  const grouping = useAppSelector(resourceExplorerGroupingSelector);
  const loading = useAppSelector(
    resourceExplorerGroupingTagKeysLoadingSelector,
  );

  const dropdownOptions = options?.map(({ value, provider }) => ({
    value,
    label: (
      <Stack direction="row" justifyContent="space-between" pr={1}>
        <TypographyWithTooltip title={value} variant="inherit">
          {value}
        </TypographyWithTooltip>
        <ProviderIcon provider={provider as ProviderType} />
      </Stack>
    ),
  }));

  const submitNewValuesHandler = useCallback(
    (newValues: string[]) => {
      onClose();
      setTimeout(() => {
        onChange?.("cost_allocation_tag", newValues);
      });
    },
    [onClose, onChange],
  );
  const dropdownOnCloseHandler = useCallback(
    (newValues: string[]) => {
      if (!values || (values && !arrayIsEqual(newValues, values))) {
        submitNewValuesHandler(newValues);
      }
    },
    [values, submitNewValuesHandler],
  );

  const DropdownHeader: DropdownHeaderComponent = useCallback(
    (props) => {
      return (
        <GroupingByTagValuesHeader
          {...props}
          onSubmit={submitNewValuesHandler}
        />
      );
    },
    [submitNewValuesHandler],
  );

  const TriggerComponent = useCallback(
    (props: FilterTriggerComponentProps) => {
      return (
        <NestedGroupingOptionTrigger
          {...props}
          needToShowCount={grouping === "cost_allocation_tag"}
        />
      );
    },
    [grouping],
  );

  if (!dropdownOptions) {
    return null;
  }

  return (
    <DropdownSelect
      label={label}
      options={dropdownOptions}
      values={values ?? []}
      TriggerComponent={TriggerComponent}
      DropdownHeader={DropdownHeader}
      showSearch={false}
      optionsLoading={loading}
      showSelectAll={false}
      listWidth={320}
      submitHandlerOnClose={dropdownOnCloseHandler}
      PopoverProps={popoverProps}
    />
  );
};

const popoverProps: Partial<PopoverProps> = {
  anchorOrigin: { vertical: "top", horizontal: "right" },
  transformOrigin: { vertical: "top", horizontal: "left" },
};
