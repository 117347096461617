import { FC } from "react";
import { LinearProgress, Stack, Typography } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import { ResourceExplorerBudgetTooltip } from "../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/budgets/tooltip/ResourceExplorerBudgetTooltip";
import { Budget } from "../../../../../../../services/cloudchipr.api";

export const BudgetsProgressCell: FC<ICellRendererParams<Budget>> = ({
  data: budget,
}) => {
  const period = budget?.period;
  const progress = budget?.progress;
  const amount = budget?.amount;
  if (!period || !progress || !amount) {
    return;
  }

  return (
    <ResourceExplorerBudgetTooltip
      amount={amount}
      progress={progress}
      period={period}
      placement="top"
    >
      <Stack direction="row" minWidth={120} alignItems="center" spacing={1}>
        <LinearProgress
          color="info"
          sx={{
            flexGrow: 1,
            height: 6,
            bgcolor: "grey.100",
            borderRadius: 1,
            "& .MuiLinearProgress-bar": {
              bgcolor: progress > 100 ? "red" : "primary",
            },
          }}
          variant="determinate"
          value={Math.min(progress, 100)}
        />
        <Typography fontSize="smaller" color="text.secondary">
          {progress}%
        </Typography>
      </Stack>
    </ResourceExplorerBudgetTooltip>
  );
};
