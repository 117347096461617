import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { commitmentsCoverageGroupingSelector } from "../../../../../../store/commitments/selectors/common/view-options/commitmentsCoverageGroupingSelector";
import { setCommitmentsCoverageGrouping } from "../../../../../../store/commitments/commitmentsSlice";
import {
  ProviderType,
  SavingsPlanCoverageGrouping,
} from "../../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";
import { changeReservationDataGridGroupingThunk } from "../../../../../../store/commitments/thunks/common/changeReservationDataGridGroupingThunk";

export const CoverageDataGridGrouping: FC = () => {
  const dispatch = useAppDispatch();
  const grouping = useAppSelector(commitmentsCoverageGroupingSelector);
  const params = useParams();

  const provider = (params?.provider ?? "aws") as ProviderType;

  const changeHandler = useCallback(
    (event: SelectChangeEvent) => {
      const grouping = event.target.value as SavingsPlanCoverageGrouping;

      if (!grouping) {
        return;
      }

      dispatch(setCommitmentsCoverageGrouping(grouping));

      dispatch(changeReservationDataGridGroupingThunk());
    },
    [dispatch],
  );

  return (
    <FormControl size="xsmall" sx={{ width: 135 }}>
      <InputLabel>Group By</InputLabel>

      <Select
        sx={selectSx}
        label="Group By"
        value={grouping}
        onChange={changeHandler}
      >
        <MenuItem value="resource_type">Resource Type</MenuItem>
        <MenuItem value="account">
          {getProviderName(provider, {
            capitalize: true,
          })}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

const selectSx = {
  bgcolor: "white",
  "& .MuiOutlinedInput-notchedOutline": { borderColor: "grey.300" },
};
