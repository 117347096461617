import { FC } from "react";
import { Stack } from "@mui/material";
import { BudgetReportsFilter } from "./BudgetReportsFilter";
import { BudgetPeriodsFilter } from "./BudgetPeriodsFilter";

export const BudgetsGridFilters: FC = () => {
  return (
    <Stack direction="row" spacing={2}>
      <BudgetReportsFilter />
      <BudgetPeriodsFilter />
    </Stack>
  );
};
