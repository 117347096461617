import { Button, Stack, Typography } from "@mui/material";
import { Row } from "@tanstack/react-table";
import { ColumnSetupType } from "../../../../../../../../storybook/data-grid/utils/types/types";
import { Reservation } from "../../../../../../../../services/cloudchipr.api";
import { HeaderCellWidthDateTooltip } from "../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import {
  commitmentsPaymentOptionLabels,
  commitmentsResourceTypeNames,
  savingsPlansTypeLabels,
} from "../../../../../common/utils/constants/labels";
import { labelsFilterFn } from "../../../../../common/utils/helpers/grid/labelsFilterFn";
import { money } from "../../../../../../../../utils/numeral/money";
import { DatesCell } from "../../../../../common/components/data-grid/cells/DatesCell";
import { TagCell } from "../../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { CommitmentsTableName } from "../../../../../common/components/data-grid/cells/CommitmentsTableName";
import { ProviderUniqueIdCell } from "../../../../../common/components/data-grid/cells/ProviderUniqueIdCell";
import { AccountCell } from "../../../../../common/components/data-grid/cells/AccountCell";
import { UtilizationCell } from "../components/cells/UtilizationCell";
import { capitalizeString } from "../../../../../../../../utils/helpers/capitalizeString";

const cellStyles = (row: Row<any>) => {
  return {
    bgcolor: row.original.state === "expires_soon" ? "#ed6c0216" : undefined,
  };
};

export const reservedInstancesTableColumns: ColumnSetupType<Reservation>[] = [
  {
    size: 300,
    minSize: 150,
    accessorKey: "provider_unique_id",
    header: "Reservation ID",
    cell: ({ row }) => (
      <ProviderUniqueIdCell id={row.original.provider_unique_id} />
    ),
    meta: { cellStyles, hideFromSettings: true },
  },

  {
    accessorKey: "type",
    header: "Type",
    size: 100,
    minSize: 80,
    cell: ({ row }) => {
      const type = row.original.type;

      return commitmentsResourceTypeNames[type] ?? null;
    },
    meta: { cellStyles },
    filterFn: (row, _, filterValue) =>
      labelsFilterFn(row.original.type, savingsPlansTypeLabels, filterValue),
  },

  {
    size: 150,
    minSize: 130,
    accessorKey: "instance_type",
    header: "Instance Type",
    meta: { cellStyles },
  },

  {
    size: 150,
    minSize: 140,
    accessorKey: "instance_count",
    header: "Instance Count",
    meta: { cellStyles },
  },

  {
    size: 250,
    minSize: 180,
    accessorKey: "account",
    header: "Linked Account",
    cell: ({ row }) => (
      <AccountCell
        accountId={row.original.account?.provider_account_id}
        accountName={row.original.account?.provider_account_name}
        isRootAccount={row.original.account?.is_provider_root_account}
      />
    ),
    meta: { cellStyles },
  },

  {
    size: 220,
    minSize: 210,
    enableResizing: false,
    sortingFn: (currentRow, nextRow) => {
      const current = currentRow.original?.used_commitment_to_date?.cost ?? 0;
      const next = nextRow.original?.used_commitment_to_date?.cost ?? 0;

      return current - next;
    },
    accessorKey: "used_commitment_to_date",
    header: () => <HeaderCellWidthDateTooltip title="Utilization" />,
    cell: ({ row }) => <UtilizationCell reservation={row.original} />,
    meta: { cellStyles, headerTitle: "Utilization" },
  },

  {
    size: 150,
    minSize: 100,
    accessorKey: "total_commitment_to_date",
    header: () => <HeaderCellWidthDateTooltip title="Reserved" />,
    meta: { cellStyles, headerTitle: "Reserved" },
    sortingFn: (currentRow, nextRow) => {
      const current = currentRow.original?.total_commitment_to_date?.cost ?? 0;
      const next = nextRow.original?.total_commitment_to_date?.cost ?? 0;

      return current - next;
    },
    cell: ({ row }) =>
      `${money(row.original.total_commitment_to_date?.cost)} (${row.original.total_commitment_to_date?.units}h)`,
  },

  {
    size: 140,
    minSize: 110,
    accessorKey: "platform",
    header: "Platform",
    meta: { cellStyles },
  },

  {
    size: 140,
    minSize: 110,
    accessorKey: "region",
    header: "Region/AZ",
    meta: { cellStyles },
  },

  {
    size: 160,
    accessorKey: "payment_option",
    header: "Payment Option",
    cell: ({ row }) => {
      const option = row.original.payment_option;

      return commitmentsPaymentOptionLabels[option] ?? null;
    },
    meta: { cellStyles },
    filterFn: (row, _, filterValue) =>
      labelsFilterFn(
        row.original.payment_option,
        commitmentsPaymentOptionLabels,
        filterValue,
      ),
  },

  {
    size: 150,
    minSize: 110,
    accessorKey: "net_savings_to_date",
    header: () => (
      <Stack alignItems="end" flex={1}>
        <HeaderCellWidthDateTooltip title="Net Savings" />
      </Stack>
    ),
    cell: ({ row }) => (
      <Typography align="right" pr={4}>
        {money(row.original.net_savings_to_date)}
      </Typography>
    ),
    meta: { cellStyles, headerTitle: "Net Savings" },
  },

  {
    size: 150,
    minSize: 130,
    accessorKey: "offering_class",
    header: "Offering Class",
    meta: { cellStyles },
    cell: ({ row }) => capitalizeString(row.original.offering_class),
  },

  {
    size: 140,
    minSize: 110,
    accessorKey: "start_date",
    header: "Start Date",
    cell: ({ row }) => <DatesCell date={row.original.start_date} />,
    meta: { cellStyles },
  },

  {
    size: 140,
    minSize: 110,
    accessorKey: "end_date",
    header: "End Date",
    cell: ({ row }) => (
      <DatesCell date={row.original.end_date} state={row.original.state} />
    ),
    meta: { cellStyles },
  },

  {
    size: 200,
    minSize: 100,
    enableSorting: false,
    accessorKey: "tags",
    header: "Tags",
    cell: ({ row }) => <TagCell tags={row.original.tags} />,
    meta: { cellStyles },
  },

  {
    enableSorting: false,
    enableResizing: false,
    accessorKey: "has_recommendation",
    header: "Recommendation",
    cell: ({ row }) => {
      const hasRecommendation = row.original.has_recommendation;

      if (hasRecommendation) {
        return (
          <Button
            size="small"
            variant="outlined"
            sx={{ textTransform: "initial" }}
          >
            <CommitmentsTableName
              id={row.original.provider_unique_id}
              type="commitment"
              name="Review"
            />
          </Button>
        );
      }

      return (
        <Typography variant="body2" color="text.disabled">
          No recommendation
        </Typography>
      );
    },
    meta: { cellStyles },
  },
];
