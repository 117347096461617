import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { ResourceExplorerGroupingNullable } from "../../../../../../../../../services/cloudchipr.api";
import { largestCostChangesSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/largestCostChangesSetupPropertyByKeySelector";
import { largestCostChangesSetupChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/largest-cost-changes/widget-change/largestCostChangesSetupChangeThunk";
import { GroupingSelectorWrapper } from "../../../../../../../common/resource-explorer-grouping-selector/GroupingSelectorWrapper";

interface LargestCostChangesGroupingSelectProps {
  disabled?: boolean;
}
export const LargestCostChangesGroupingSelect: FC<
  LargestCostChangesGroupingSelectProps
> = ({ disabled }) => {
  const dispatch = useAppDispatch();

  const groupBy = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("grouping"),
  );
  const groupValues = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("group_values"),
  );

  const groupingChangeHandler = useCallback(
    (grouping: ResourceExplorerGroupingNullable, groupValues?: string[]) => {
      dispatch(
        largestCostChangesSetupChangeThunk({
          grouping,
          group_values: groupValues,
        }),
      );
    },
    [dispatch],
  );

  return (
    <GroupingSelectorWrapper
      value={groupBy}
      disabled={disabled}
      hiddenGroupings={["resource"]}
      groupValues={groupValues ?? null}
      onChange={groupingChangeHandler}
    />
  );
};
