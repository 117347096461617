import { FC } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { TextFieldProps } from "@mui/material/TextField/TextField";

import { GroupingSelector } from "./GroupingSelector";
import { GroupingSelectorV2 } from "../resource-explorer-grouping-selector-v2/GroupingSelectorV2";
import { ResourceExplorerGroupingNullable } from "../../../../services/cloudchipr.api";

interface GroupingSelectorWrapperProps {
  disabled?: boolean;
  dropDownMaxHeight?: number;
  groupValues?: string[];
  value: ResourceExplorerGroupingNullable;
  triggerSize?: TextFieldProps["size"];
  hiddenGroupings?: ResourceExplorerGroupingNullable[];
  onChange(
    grouping: ResourceExplorerGroupingNullable,
    groupValue?: string[],
  ): void;
}
export const GroupingSelectorWrapper: FC<GroupingSelectorWrapperProps> = ({
  value,
  groupValues,
  disabled,
  onChange,
  hiddenGroupings,
  triggerSize,
  dropDownMaxHeight,
}) => {
  const enableResourceExplorerGroupings = useFlag(
    "EnableResourceExplorerGroupings",
  );

  if (enableResourceExplorerGroupings) {
    return (
      <GroupingSelectorV2
        dropDownMaxHeight={dropDownMaxHeight}
        value={value}
        triggerSize={triggerSize}
        groupValues={groupValues}
        onChange={onChange}
        disabled={disabled}
        hiddenGroupings={hiddenGroupings}
      />
    );
  }

  return (
    <GroupingSelector
      dropDownMaxHeight={dropDownMaxHeight}
      value={value}
      triggerSize={triggerSize}
      groupValues={groupValues}
      onChange={onChange}
      disabled={disabled}
      hiddenGroupings={hiddenGroupings}
    />
  );
};
