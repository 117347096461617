import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { NavItem } from "../../../../navigation/components/nav-item/NavItem";
import AWSSvgIcon from "../../../../../assets/images/logos/providers/AWSSvgIcon";
import GCPSvgIcon from "../../../../../assets/images/logos/providers/GCPSvgIcon";
import AzureSvgIcon from "../../../../../assets/images/logos/providers/AzureSvgIcon";
import { useNavigationOverlayContext } from "../../../../navigation/components/nav-item/utils/useNavigationOverlay.context";
import { useAppNavOverlayContext } from "../../../utils/useAppNavOverlay.context";

interface CommitmentsNavigationItemProps {
  provider: ProviderType;
  org: string;
  orgId: string;
}

export const CommitmentsNavigationItem: FC<CommitmentsNavigationItemProps> = ({
  provider,
  org,
  orgId,
}) => {
  const navigate = useNavigate();
  const { onNavigate } = useNavigationOverlayContext();
  const { onClose } = useAppNavOverlayContext();

  const path = `/commitments/${provider}/${orgId}`;

  const selected = true;

  const buttonClickHandler = useCallback(() => {
    navigate(path);
    onNavigate?.();
    onClose?.();
  }, [navigate, onNavigate, onClose, path]);

  return (
    <NavItem
      nested
      onButtonClick={buttonClickHandler}
      key={path}
      primary={org}
      icon={providerIconsMap.get(provider)}
      selected={selected}
    />
  );
};

const providerIconsMap = new Map<ProviderType, any>([
  ["aws", AWSSvgIcon],
  ["gcp", GCPSvgIcon],
  ["azure", AzureSvgIcon],
]);
