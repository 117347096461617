import { FC } from "react";
import { AzureCommitmentScope } from "../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";

export const ScopeCell: FC<AzureCommitmentScope> = ({ id, name, type }) => {
  const secondary = name ?? id;
  const secondaryText = secondary ? "- " + secondary : "";
  return <TypographyWithTooltip title={`${labels?.[type]} ${secondaryText}`} />;
};

const labels: Record<AzureCommitmentScope["type"], string> = {
  single: "Single",
  shared: "Shared",
  management_group: "Management group",
};
