import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAppLoading } from "./commonSlice";
import { getCurrentOrganisationSettingsThunk } from "./thunks/org-current-settings/getCurrentOrganisationSettingsThunk";
import { getAllProvidersBillingStatusThunk } from "./thunks/billing-status/getAllProvidersBillingStatusThunk";
import { getProfileThunk } from "../profile/profileThunks";
import { getCurrentSubscriptionThunk } from "../subscriptions/subscriptionsThunks";
import { getCurrentOrganizationThunk } from "../organizations/organizationsThunks";
import { getAccountsThunk } from "../accounts/thunks/getAccountsThunk";
import { getAllowedFiltersThunk } from "../filters/thunks/getAllowedFiltersThunk";
import { setLogout } from "../auth/authSlice";
import { getResourceExplorerVisibilityHierarchyThunk } from "../resource-explorer/thunks/resource-explorer-hierarchy-visibility/getResourceExplorerVisibilityHierarchyThunk";
import { getDashboardsVisibilityHierarchyThunk } from "../dashboards/thunks/dashboard-hierarchy-visibility/getDashboardsVisibilityHierarchyThunk";
import { getResourceExplorerPossibleGroupingsThunk } from "../resource-explorer/thunks/common/getResourceExplorerPossibleGroupingsThunk";

export const postAuthDataFetchThunk = createAsyncThunk(
  "accounts/postAuthDataFetch",
  async (_, { dispatch }) => {
    const results = await Promise.all([
      dispatch(getCurrentOrganizationThunk()),
      dispatch(getProfileThunk()).unwrap(),
      dispatch(getCurrentSubscriptionThunk()),
      dispatch(getCurrentOrganisationSettingsThunk()),
    ]);
    const isSubscriptionExists = results?.[2]?.payload;
    const profileData = results?.[1];

    if (
      profileData?.status === "awaiting_invitation" ||
      profileData?.status === "in_review"
    ) {
      return;
    }
    if (isSubscriptionExists) {
      await Promise.all([
        dispatch(getAllowedFiltersThunk()),
        await dispatch(getAccountsThunk()),
        dispatch(getAllProvidersBillingStatusThunk()),
        dispatch(getResourceExplorerVisibilityHierarchyThunk()),
        dispatch(getDashboardsVisibilityHierarchyThunk()),
        dispatch(getResourceExplorerPossibleGroupingsThunk()),
      ]);
      dispatch(setAppLoading(false));
    } else {
      dispatch(setLogout(true));
    }
  },
);
