import { resourceExplorerInternalPossibleGroupingsSelector } from "./resourceExplorerInternalPossibleGroupingsSelector";
import { RootState } from "../../../store";

import { ResourceExplorerPossibleGroupingsFormattedData } from "../../../../components/pages/common/resource-explorer-grouping-selector-v2/utils/types";
import { generateResourceExplorerPossibleGroupingsFormatedData } from "../possible-groupings/utils/helpers";

export const resourceExplorerInternalPossibleGroupingsFormattedDataSelector = (
  state: RootState,
  organisationId: string,
): ResourceExplorerPossibleGroupingsFormattedData | undefined => {
  const data = resourceExplorerInternalPossibleGroupingsSelector(
    state,
    organisationId,
  );

  if (!data) {
    return;
  }

  return generateResourceExplorerPossibleGroupingsFormatedData(data);
};
