import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { budgetsAppliedReportsFiltersSelector } from "./filters/applied/budgetsAppliedReportsFiltersSelector";
import { budgetsAppliedPeriodsFiltersSelector } from "./filters/applied/budgetsAppliedPeriodsFiltersSelector";
import { currentBudgetsDataSelector } from "../currentBudgetsDataSelector";
import { Budget } from "../../../../services/cloudchipr.api";

export const currentBudgetFilteredDataSelector = createDraftSafeSelector(
  [
    currentBudgetsDataSelector,
    budgetsAppliedReportsFiltersSelector,
    budgetsAppliedPeriodsFiltersSelector,
  ],
  (budgets, reportsFilters, periodsFilters): Budget[] | undefined => {
    return budgets?.filter((budget) => {
      let passed = true;

      if (reportsFilters?.length) {
        const report = budget.view.name;
        passed = passed && reportsFilters.includes(report);
      }

      if (periodsFilters?.length) {
        const period = budget.period;
        passed = passed && periodsFilters.includes(period);
      }

      return passed;
    });
  },
);
