import { FC, useEffect, useRef } from "react";
import { Stack } from "@mui/material";
import { LiveResourceFiltersNavigationItemByResourceType } from "./LiveResourceFiltersNavigationItemByResourceType";
import { LiveResourceFiltersNavigationAllResourcesItem } from "./LiveResourceFiltersNavigationAllResourcesItem";
import { allResourcesByProvider } from "../../../../../utils/constants/resources/resourceGroupsByProvider";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { LiveUsageNavigationResourceType } from "../../../../pages/live-usage-mgmt/components/navigation/utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { getIsResourceTypeHiddenByFeatureToggle } from "../../../../pages/live-usage-mgmt/utils/helpers/getIsResourceTypeHiddenByFeatureToggle";

interface LiveResourceFiltersNavigationProps {
  provider: ProviderType;
  activeResourceType: LiveUsageNavigationResourceType;
  setActiveResourceType(rt: LiveUsageNavigationResourceType): void;
}

export const LiveResourceFiltersNavigation: FC<
  LiveResourceFiltersNavigationProps
> = ({ provider, activeResourceType, setActiveResourceType }) => {
  const wrapperRef = useRef<null | HTMLDivElement>(null);
  const activeRtRef = useRef<null | HTMLDivElement>(null);
  const resources = allResourcesByProvider.get(provider);

  useEffect(() => {
    activeRtRef.current?.scrollIntoView();
  }, []);

  return (
    <Stack ref={wrapperRef} sx={wrapperStyles}>
      <LiveResourceFiltersNavigationAllResourcesItem
        setActiveResourceType={setActiveResourceType}
        activeResourceType={activeResourceType}
      />

      {resources?.map((resourceType) => {
        if (getIsResourceTypeHiddenByFeatureToggle(resourceType)) {
          return null;
        }

        const active = activeResourceType === resourceType;

        return (
          <div ref={active ? activeRtRef : undefined} key={resourceType}>
            <LiveResourceFiltersNavigationItemByResourceType
              active={active}
              resourceType={resourceType}
              setActiveResourceType={setActiveResourceType}
            />
          </div>
        );
      })}
    </Stack>
  );
};

const wrapperStyles = {
  pr: 1,
  overflow: "auto",
  background: `
  linear-gradient(white 60%, white) center top,
  linear-gradient(white, white 70%) center bottom,
  radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0)) center bottom
  `,
  backgroundRepeat: "no-repeat",
  backgroundSize: "0 0, 100% 40px, 700% 44px, 100% 14px",
  backgroundAttachment: "local, local, scroll, scroll",
};
