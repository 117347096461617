import { FC } from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { AppNavItemWrapper } from "../AppNavItemWrapper";

export const SettingsNavigation: FC = () => {
  const location = useLocation();
  const settingsSelected = location.pathname.startsWith("/settings");
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  return (
    <AppNavItemWrapper
      to="/settings/profile"
      primary="Settings"
      Icon={SettingsOutlinedIcon}
      navCollapsed={navigationCollapsed}
      selected={settingsSelected}
    />
  );
};
