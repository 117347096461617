import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { savingsOpportunitiesDataSelector } from "./savingsOpportunitiesDataSelector";
import { EmbeddedAccount } from "../../../services/cloudchipr.api";
import { possibleEfforts } from "../../../components/pages/savings-opportunities/utils/constants/common";

export const savingsOpportunitiesAggregatedDataSelector =
  createDraftSafeSelector([savingsOpportunitiesDataSelector], (data) => {
    const aggregatedData: Record<string, any> = {};
    const aggregatedDataCounts: Record<string, number> = {};

    if (!data) {
      return;
    }

    data.forEach((opportunity) => {
      Object.entries(opportunity).forEach(([key, value]) => {
        // Exception checks by key value

        if (key === "based_on_past") {
          aggregatedData[key] = new Set([
            ...(aggregatedData[key] ?? []),
            value,
          ]);
          aggregatedDataCounts[key] = aggregatedData[key].size;

          return;
        }

        if (key === "implementation_effort") {
          const currentEffortIndex = possibleEfforts.findIndex(
            (effort) => effort === value,
          );

          // Formating from 5 numbers to 3
          const formatedIndex = Math.ceil((currentEffortIndex + 1) / 2);

          aggregatedData[key] = new Set([
            ...(aggregatedData[key] ?? []),
            formatedIndex,
          ]);
          aggregatedDataCounts[key] = aggregatedData[key].size;
          return;
        }

        // Checks by types (number, string, EmbeddedAccount)

        if (typeof value === "number") {
          aggregatedData[key] = (aggregatedData[key] || 0) + value;
          aggregatedDataCounts[key] = aggregatedData[key];
          return;
        }

        if (typeof value === "string") {
          aggregatedData[key] = new Set([
            ...(aggregatedData[key] ?? []),
            value,
          ]);
          aggregatedDataCounts[key] = aggregatedData[key].size;
          return;
        }

        if (isEmbeddedAccount(value)) {
          aggregatedData["account.provider"] = new Set([
            ...(aggregatedData["account.provider"] ?? []),
            value.provider,
          ]);
          aggregatedDataCounts["account.provider"] =
            aggregatedData["account.provider"].size;

          aggregatedData["account.provider_account_id"] = new Set([
            ...(aggregatedData["account.provider_account_id"] ?? []),
            value.id,
          ]);
          aggregatedDataCounts["account.provider_account_id"] =
            aggregatedData["account.provider_account_id"].size;
        }
      });
    });

    return aggregatedDataCounts;
  });

export const isEmbeddedAccount = (account: any): account is EmbeddedAccount => {
  return !!account?.id && !!account?.provider && !!account?.provider_account_id;
};
