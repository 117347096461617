import { FC } from "react";
import { Skeleton, Stack } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ProviderIcon } from "../../../../../../common/ProviderIcon";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { SIDEBAR_WIDTH } from "../../../../../../../utils/constants/style-sizes";
import { useAppSelector } from "../../../../../../../store/hooks";
import { FilterTriggerComponentProps } from "../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { liveUsageMgmtProviderSelector } from "../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtSelectedAccountsSelector } from "../../../../../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageMgmtSelectedAccountsSelector";

export const LiveUsageMgmtSelectedAccountNavigationTriggerComponent: FC<
  FilterTriggerComponentProps
> = ({ onClick, open }) => {
  const provider = useAppSelector(liveUsageMgmtProviderSelector);
  const selectedAccounts = useAppSelector(
    liveUsageMgmtSelectedAccountsSelector,
  );

  if (!selectedAccounts || !provider) {
    return <Skeleton variant="text" height={100} width={SIDEBAR_WIDTH - 20} />;
  }

  const firstAccountName =
    selectedAccounts.at(0)?.provider_account_name ??
    `ID: ${selectedAccounts.at(0)?.provider_account_id}`;

  const title =
    selectedAccounts?.length === 1
      ? firstAccountName
      : `${selectedAccounts?.length} selected accounts`;

  return (
    <Stack
      py={1.25}
      px={1}
      direction="row"
      borderRadius={2}
      onClick={onClick}
      bgcolor="grey.100"
      alignItems="center"
      sx={{ cursor: "pointer" }}
      justifyContent="space-between"
      spacing={1}
    >
      <ProviderIcon provider={provider} width={24} />

      <TypographyWithTooltip title={title} variant="subtitle2" width="100%" />

      {open ? (
        <ArrowRightIcon sx={{ mr: 1 }} />
      ) : (
        <ArrowDropDownIcon sx={{ mr: 1 }} />
      )}
    </Stack>
  );
};
