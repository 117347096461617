import { FC, useCallback, useEffect, useState } from "react";
import { Box, Button, Popover, PopoverOrigin, Stack } from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { LiveResourceFiltersNavigation } from "./navigation/LiveResourceFiltersNavigation";
import { LiveResourceFiltersContent } from "./content/LiveResourceFiltersContent";
import { useRefs } from "../../../../utils/hooks/useRefs";
import { useLiveUsageFiltersPopoverHeight } from "../utils/hooks/useLiveResourceFiltersPopoverHeight.hook";
import { useAppSelector } from "../../../../store/hooks";
import { liveUsageMgmtProviderSelector } from "../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { resourceTypeFilterSubmitHandlerKeyGenerator } from "../utils/helpers/resourceTypeFilterSubmitHandlerKeyGenerator";
import { LiveUsageNavigationResourceType } from "../../../pages/live-usage-mgmt/components/navigation/utils/hooks/useLiveUsageMgmtPageNavigate.hook";

interface LiveResourceFiltersPopoverProps {
  open: boolean;
  singleResource?: boolean;
  popoverStaticHeight?: string | number;
  onClose(): void;
  resourceType: LiveUsageNavigationResourceType;
  anchor: HTMLElement | null;
}

export const LiveResourceFiltersPopover: FC<
  LiveResourceFiltersPopoverProps
> = ({
  open,
  anchor,
  popoverStaticHeight,
  singleResource,
  onClose,
  resourceType,
}) => {
  const { handleMouseDown, height } = useLiveUsageFiltersPopoverHeight();
  const provider = useAppSelector(liveUsageMgmtProviderSelector);

  const { setRefFromKey, refs } = useRefs();

  const [activeResourceType, setActiveResourceType] = useState<
    LiveUsageNavigationResourceType | undefined
  >();

  const applyHandler = useCallback(() => {
    if (!refs) {
      return;
    }

    Object.entries(refs).forEach(([key, value]) => {
      const foundCallback = key?.endsWith(
        resourceTypeFilterSubmitHandlerKeyGenerator(),
      );

      if (foundCallback && typeof value === "function") {
        value();
      }
    });
  }, [refs]);

  useEffect(() => {
    setActiveResourceType(resourceType);
  }, [resourceType]);

  if (!activeResourceType) {
    return null;
  }

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={defaultAnchorOrigin}
      transformOrigin={defaultTransformOrigin}
    >
      <Stack
        minWidth={popoverStaticHeight ? 1000 : 1250}
        maxHeight="90dvh"
        height={popoverStaticHeight ?? height}
        overflow="hidden"
      >
        {provider && (
          <Stack flex={1} direction="row" p={2} overflow="auto">
            {!singleResource && (
              <LiveResourceFiltersNavigation
                provider={provider}
                activeResourceType={activeResourceType}
                setActiveResourceType={setActiveResourceType}
              />
            )}

            <LiveResourceFiltersContent
              onClose={onClose}
              provider={provider}
              setRefFromKey={setRefFromKey}
              activeResourceType={activeResourceType}
            />
          </Stack>
        )}

        <Stack
          pr={2}
          spacing={1}
          direction="row"
          justifyContent="end"
          pb={popoverStaticHeight ? 2 : 0}
        >
          <Button onClick={onClose} color="tertiary">
            Cancel
          </Button>

          <Button type="submit" variant="contained" onClick={applyHandler}>
            Apply
          </Button>
        </Stack>

        {!popoverStaticHeight && (
          <Box sx={dragHandleStyles} onMouseDown={handleMouseDown}>
            <DragHandleIcon color="action" />
          </Box>
        )}
      </Stack>
    </Popover>
  );
};

const defaultAnchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const defaultTransformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};

const dragHandleStyles = {
  lineHeight: 1,
  width: "100%",
  bgcolor: "white",
  textAlign: "center",
  cursor: "ns-resize",
};
