import { FC, useCallback } from "react";
import TabContext from "@mui/lab/TabContext";
import { useDidMount, useWillUnmount } from "rooks";
import { Stack } from "@mui/material";
import { TabPanel } from "@mui/lab";
import { useSearchParams } from "react-router-dom";
import { BudgetsAndAlertsTabs } from "./components/BudgetsAndAlertsTabs";
import { AlertsDrawer } from "./components/drawer/AlertsDrawer";
import { AlertsCostAnomaly } from "./components/grids/cost-anomaly/AlertsCostAnomaly";
import { AlertsUtilization } from "./components/grids/utilization/AlertsUtilization";
import { isAlertTabType } from "./utils/helpers/isAlertTabType";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { alertsActiveTabSelector } from "../../../store/alerts/selectors/alertsActiveTabSelector";
import {
  resetAlerts,
  resetAlertsData,
  setAlertsActiveTab,
  toggleAlertsOpenDrawer,
} from "../../../store/alerts/alertsSlice";
import { getAlertsPageDataThunk } from "../../../store/alerts/thunks/getAlertsPageDataThunk";
import { Budgets } from "../budgets/Budgets";
import { resetCurrentBudgetFilters } from "../../../store/budgets/budgetsSlice";

export const BudgetsAndAlerts: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const alertTypeFromPath = searchParams.get("alertType");
  const alertsActiveTab = useAppSelector(alertsActiveTabSelector);

  const handleDrawerClose = useCallback(() => {
    dispatch(toggleAlertsOpenDrawer());
    dispatch(resetAlertsData());
  }, [dispatch]);

  useDidMount(() => {
    dispatch(getAlertsPageDataThunk());

    if (
      alertTypeFromPath !== alertsActiveTab &&
      isAlertTabType(alertTypeFromPath)
    ) {
      dispatch(setAlertsActiveTab(alertTypeFromPath));
    }
  });

  useWillUnmount(() => {
    dispatch(resetAlerts());
    dispatch(resetCurrentBudgetFilters());
  });

  return (
    <Stack flexGrow={1} bgcolor="white">
      <TabContext value={alertsActiveTab}>
        <BudgetsAndAlertsTabs />

        <TabPanel value="budget" sx={{ p: 0, height: "100%" }}>
          <Budgets />
        </TabPanel>
        <TabPanel value="costAnomaly" sx={{ p: 0 }}>
          <AlertsCostAnomaly />
        </TabPanel>
        <TabPanel value="utilization" sx={{ p: 0 }}>
          <AlertsUtilization />
        </TabPanel>
      </TabContext>

      <AlertsDrawer onClose={handleDrawerClose} />
    </Stack>
  );
};
