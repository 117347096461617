import { FC } from "react";
import { Box, Card } from "@mui/material";
import { AzureReservationsMultiTypeChart } from "./chart/multi-type-chart/AzureReservationsMultiTypeChart";
import { AzureReservationsGaugeChart } from "./chart/AzureReservationsGaugeChart";
import { AzureReservationsDataGrid } from "./data-grid/AzureReservationsDataGrid";

export const AzureReservationsContent: FC = () => {
  return (
    <Card sx={{ p: 2 }} variant="outlined">
      <AzureReservationsMultiTypeChart />
      <AzureReservationsGaugeChart />
      <Box mt={4}>
        <AzureReservationsDataGrid />
      </Box>
    </Card>
  );
};
