import { createAsyncThunk } from "@reduxjs/toolkit";
import { getReservationsForGranularThunk } from "./reservations/getReservationsForGranularThunk";
import { getSavingsPlansForGranularThunk } from "./savings-plans/getSavingsPlansForGranularThunk";
import { getAzureReservationsForGranularThunk } from "./azure/reservations/getAzureReservationsForGranularThunk";
import { DateDataPoint } from "../../../services/cloudchipr.api";
import { setCommitmentsDateGranularity } from "../commitmentsSlice";
import { RootState } from "../../store";
import { commitmentsTypeTabSelector } from "../selectors/common/commitmentsTypeTabSelector";
import { commitmentsCurrentOrgProviderSelector } from "../selectors/common/commitmentsCurrentOrgProviderSelector";

export const changeCommitmentsDateGranularityThunk = createAsyncThunk(
  "commitments/changeCommitmentDateRange",
  (dataPoint: DateDataPoint, { dispatch, getState }) => {
    const state = getState() as RootState;
    const activeTab = commitmentsTypeTabSelector(state);
    const provider = commitmentsCurrentOrgProviderSelector(state);

    dispatch(setCommitmentsDateGranularity(dataPoint));

    if (activeTab === "reservation") {
      if (provider === "azure") {
        dispatch(getAzureReservationsForGranularThunk());
      } else {
        dispatch(getReservationsForGranularThunk());
      }
    } else {
      dispatch(getSavingsPlansForGranularThunk());
    }
  },
);
