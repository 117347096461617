import React, { FC } from "react";
import { Stack } from "@mui/material";
import { UnauthorizedDashboardCostBreakdownWidget } from "./UnauthorizedDashboardCostBreakdownWidget";
import { DashboardWidgetCostBreakdown } from "../../../../../services/cloudchipr.api";
import { WidgetLoadingState } from "../../../../pages/dashboard/components/widgets/common/WidgetLoadingState";
import { useUnauthorizedCostBreakdownHook } from "../../utils/hooks/useUnauthorizedCostBreakdown.hook";
import { UnauthorizedWidgetHookArguments } from "../../utils/types/types";
import { UnauthorizedWidgetHeader } from "../UnauthorizedWidgetHeader";

interface UnauthorizedDashboardCostBreakdownWidgetWrapperProps
  extends DashboardWidgetCostBreakdown,
    Omit<UnauthorizedWidgetHookArguments, "widgetId"> {
  onWidgetMount?(): void;
}

export const UnauthorizedDashboardCostBreakdownWidgetWrapper: FC<
  UnauthorizedDashboardCostBreakdownWidgetWrapperProps
> = ({
  id,
  organisationId,
  categoryId,
  overrideQueryDates,
  name,
  dates,
  grouping,
  ...props
}) => {
  const { data, error, isError, isFetching, isUninitialized } =
    useUnauthorizedCostBreakdownHook({
      widgetId: id,
      organisationId,
      categoryId,
      overrideQueryDates,
    });

  if (isFetching || isUninitialized) {
    return (
      <Stack flexGrow={1} overflow="auto">
        <UnauthorizedWidgetHeader
          name={name}
          dates={dates}
          grouping={grouping}
          organisationId={organisationId ?? ""}
        />
        <WidgetLoadingState />
      </Stack>
    );
  }

  return (
    <UnauthorizedDashboardCostBreakdownWidget
      {...props}
      data={data}
      isError={isError}
      error={error}
      id={id}
      dates={dates}
      name={name}
      grouping={grouping}
      organisationId={organisationId ?? ""}
    />
  );
};
