import { FC, useCallback } from "react";
import { PopoverProps } from "@mui/material";
import { CommitmentsDateRangeSelector } from "./CommitmentsDateRangeSelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { commitmentsDatesSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";
import { Dates } from "../../../../../../../services/cloudchipr.api";
import { commitmentsTypeTabSelector } from "../../../../../../../store/commitments/selectors/common/commitmentsTypeTabSelector";
import { changeCommitmentDateRangeThunk } from "../../../../../../../store/commitments/thunks/changeCommitmentDateRangeThunk";

export const CommitmentsDateRange: FC = () => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(commitmentsDatesSelector);
  const activeTab = useAppSelector(commitmentsTypeTabSelector);

  const dateChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(changeCommitmentDateRangeThunk({ dates }));
    },
    [dispatch],
  );

  return (
    <CommitmentsDateRangeSelector
      size="xsmall"
      dates={dates}
      PopoverProps={popoverProps}
      onChange={dateChangeHandler}
    />
  );
};

const popoverProps: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};
