import Typography from "@mui/material/Typography";
import { Stack, Tooltip } from "@mui/material";
import StarsIcon from "@mui/icons-material/Stars";
import { ColumnSetupType } from "../../../../../../../../../storybook/data-grid/utils/types/types";
import { AzureReservationsCoverageDataWithId } from "../../../../../../common/utils/types";
import {
  AccountUsage,
  AzureCommitmentCoverageData,
  ResourceTypeUsage,
  SavingsPlanCoverageGrouping,
} from "../../../../../../../../../services/cloudchipr.api";
import { CommitmentsTableName } from "../../../../../../common/components/data-grid/cells/CommitmentsTableName";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { HeaderCellWidthDateTooltip } from "../../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import { CommitmentsProgressBar } from "../../../../../../common/components/CommitmentsProgressBar";
import { money } from "../../../../../../../../../utils/numeral/money";
import { AzureReservationsColumnsHeaderCellWithTotalAndDate } from "../components/cells/AzureReservationsColumnsHeaderCellWithTotalAndDate";

const groupedByAccountColumns: ColumnSetupType<AzureReservationsCoverageDataWithId>[] =
  [
    {
      minSize: 100,
      accessorKey: "type",
      header: "Subscription",
      meta: { hideFromSettings: true },
      cell: ({ row }) => {
        const data = row.original as AccountUsage;
        return (
          <CommitmentsTableName
            type="account"
            id={row.original.id}
            name={
              <Typography variant="body2" align="left" component="div">
                <Stack direction="row" spacing={0.5}>
                  {data.is_management_account && (
                    <Tooltip title="Management account" placement="top" arrow>
                      <StarsIcon color="primary" fontSize="small" />
                    </Tooltip>
                  )}

                  <TypographyWithTooltip variant="body2" title={data?.name} />
                </Stack>

                <TypographyWithTooltip
                  variant="body2"
                  title={data?.provider_account_id}
                />
              </Typography>
            }
          />
        );
      },
    },
    {
      minSize: 220,
      accessorKey: "covered_usage",
      header: () => (
        <AzureReservationsColumnsHeaderCellWithTotalAndDate
          title="Spend Covered by RIs"
          type="coveredUsage"
        />
      ),
      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.covered_usage)}
        </Typography>
      ),
      meta: { headerTitle: "Spend Covered by RIs" },
    },
    {
      minSize: 210,
      accessorKey: "uncovered_usage",
      header: () => (
        <AzureReservationsColumnsHeaderCellWithTotalAndDate
          type="unCoveredUsage"
          title="Not Covered (On-Demand)"
        />
      ),
      meta: { headerTitle: "Not Covered (On-Demand)" },
      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.uncovered_usage)}
        </Typography>
      ),
    },
    {
      minSize: 200,
      accessorKey: "net_savings",
      header: () => (
        <AzureReservationsColumnsHeaderCellWithTotalAndDate
          title="Net Savings"
          type="netSavings"
        />
      ),
      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.net_savings)}
        </Typography>
      ),
      meta: { headerTitle: "Net Savings" },
    },
    {
      enableResizing: false,
      minSize: 150,
      accessorKey: "coverage",
      meta: { headerTitle: "Coverage" },
      header: () => <HeaderCellWidthDateTooltip title="Coverage" />,
      cell: ({ row }) => (
        <CommitmentsProgressBar value={row.original.coverage} />
      ),
    },
  ];

const groupedByResourceColumns: ColumnSetupType<AzureReservationsCoverageDataWithId>[] =
  [
    {
      minSize: 140,
      id: "resource_type",
      accessorKey: "resource_type" as keyof AzureCommitmentCoverageData,
      header: "Resource Type",
      meta: { hideFromSettings: true },
      cell: ({ row }) => {
        const data = row.original as ResourceTypeUsage;
        return (
          <CommitmentsTableName
            type="resource_type"
            id={row.original.id}
            name={
              <TypographyWithTooltip
                title={data.resource_type ?? ""}
                variant="body2"
                align="left"
              />
            }
          />
        );
      },
    },

    {
      minSize: 150,
      accessorKey: "product_name" as keyof AzureCommitmentCoverageData,
      header: "Product Name",
      meta: { headerTitle: "Not Covered (On-Demand)" },
      cell: ({ row }) => {
        const data = row.original as ResourceTypeUsage;
        return <TypographyWithTooltip title={data?.product_name ?? ""} />;
      },
    },
    {
      minSize: 80,
      id: "region",
      accessorKey: "region" as keyof AzureCommitmentCoverageData,
      header: "Region",
      cell: ({ row }) => {
        const data = row.original as ResourceTypeUsage;
        return data?.region ?? null;
      },
    },
    {
      minSize: 200,
      accessorKey: "unit_of_measure" as keyof AzureCommitmentCoverageData,
      header: () => (
        <AzureReservationsColumnsHeaderCellWithTotalAndDate
          title="Spend Covered by RIs"
          type="coveredUsage"
        />
      ),

      cell: ({ row }) => {
        const data = row.original as ResourceTypeUsage;
        return (
          <TypographyWithTooltip
            title={money(data?.covered_units)}
            align="right"
            pr={4}
            variant="body2"
          />
        );
      },
      meta: { headerTitle: "Spend Covered by RIs" },
    },
    {
      minSize: 160,
      accessorKey: "covered_units" as keyof AzureCommitmentCoverageData,
      header: "Covered",
      cell: ({ row }) => {
        const data = row.original as ResourceTypeUsage;
        const measure = data.unit_of_measure
          ? ` (${data.unit_of_measure})`
          : "";
        return (
          // height 45 is for virtualization
          <Stack justifyContent="center" height={45}>
            <TypographyWithTooltip
              title={data.covered_units + measure}
              variant="body2"
            />
          </Stack>
        );
      },
      meta: { headerTitle: "Covered " },
    },
    {
      minSize: 210,
      accessorKey: "uncovered_usage",
      header: () => (
        <AzureReservationsColumnsHeaderCellWithTotalAndDate
          type="unCoveredUsage"
          title="Not Covered (On-Demand)"
        />
      ),
      meta: { headerTitle: "Not Covered (On-Demand)" },
      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.uncovered_usage)}
        </Typography>
      ),
    },
    {
      minSize: 200,
      accessorKey: "net_savings",
      header: () => (
        <AzureReservationsColumnsHeaderCellWithTotalAndDate
          title="Net Savings"
          type="netSavings"
        />
      ),
      cell: ({ row }) => (
        <TypographyWithTooltip
          title={money(row.original.net_savings)}
          align="right"
          pr={4}
          variant="body2"
        />
      ),
      meta: { headerTitle: "Net Savings" },
    },
    {
      enableResizing: false,
      minSize: 150,
      accessorKey: "coverage",
      meta: { headerTitle: "Coverage" },
      header: () => <HeaderCellWidthDateTooltip title="Coverage" />,
      cell: ({ row }) => (
        <CommitmentsProgressBar value={row.original.coverage} />
      ),
    },
  ];

export const azureReservationCoverageTableColumns = (
  grouping: SavingsPlanCoverageGrouping,
): ColumnSetupType<any>[] => {
  // TODO add type
  if (grouping === "account") {
    return groupedByAccountColumns;
  }

  if (grouping === "resource_type") {
    return groupedByResourceColumns;
  }

  return [];
};
