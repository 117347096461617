import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { AzureReservationsCoverageDataWithId } from "../../../../../../components/pages/commtiments/common/utils/types";
import { addIdInCoverageData } from "../../../../utils/helpers/addIdInCoverageData";
import { AzureCommitmentCoverageData } from "../../../../../../services/cloudchipr.api";
import { azureReservationsDataForTableSelector } from "../azureReservationsDataForTableSelector";

export const azureReservationsCoverageDataSelector = createDraftSafeSelector(
  [azureReservationsDataForTableSelector],
  (data): AzureReservationsCoverageDataWithId[] | undefined => {
    return addIdInCoverageData<AzureCommitmentCoverageData>(
      data?.utilization_and_coverage?.coverage_data,
    );
  },
);
