import { GridApi } from "ag-grid-community";
import { FC, useCallback, useState } from "react";
import { Button, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { BudgetsGridFilters } from "./filters/BudgetsGridFilters";
import { GlobalFilter } from "../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { AGGridColumnsToolPanel } from "../../../../common/ag-grid/components/AGGridColumnsToolPanel";
import { useAppAbility } from "../../../../../../services/permissions";

interface BudgetsGridToolbarProps {
  gridApi: GridApi | null;
}

export const BudgetsGridToolbar: FC<BudgetsGridToolbarProps> = ({
  gridApi,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { can } = useAppAbility();
  const canCreateBudget = can("create", "budget");

  const searchChangeHandler = useCallback(
    (keyword: string) => {
      gridApi?.setGridOption("quickFilterText", keyword);
      setSearchValue(keyword);
    },
    [gridApi],
  );

  return (
    <Stack
      py={2.5}
      px={1.5}
      spacing={2}
      direction="row"
      justifyContent="space-between"
      bgcolor="white"
      zIndex="appBar"
      borderBottom={1}
      position="sticky"
      borderColor="grey.300"
    >
      <BudgetsGridFilters />

      <Stack direction="row" spacing={2}>
        <Button
          color="primary"
          size="small"
          variant="outlined"
          to="/budgets/create"
          component={RouterLink}
          disabled={!canCreateBudget}
          startIcon={<AddIcon />}
          sx={{ textTransform: "none" }}
        >
          Create Budget
        </Button>

        <GlobalFilter
          globalFilter={searchValue}
          setGlobalFilter={searchChangeHandler}
          size="xsmall"
          sx={{ width: 260 }}
        />

        <AGGridColumnsToolPanel gridApi={gridApi} />
      </Stack>
    </Stack>
  );
};
