import { ColDef, IMultiFilterParams } from "ag-grid-community";
import { SavingsOpportunityActionTypeCellRenderer } from "./cell-renderers/SavingsOpportunityActionTypeCellRenderer";
import { SavingsOpportunityServiceCellRenderer } from "./cell-renderers/SavingsOpportunityServiceCellRenderer";
import { SavingsOpportunityImplementationEffortCellRenderer } from "./cell-renderers/SavingsOpportunityImplementationEffortCellRenderer";
import { SavingsOpportunityAccountCellRenderer } from "./cell-renderers/SavingsOpportunityAccountCellRenderer";
import { SavingsOpportunityTagsCellRenderer } from "./cell-renderers/SavingsOpportunityTagsCellRenderer";
import { SavingsOpportunityMonthlySavingsCellRenderer } from "./cell-renderers/SavingsOpportunityMonthlySavingsCellRenderer";
import { getSimpleTypographyCellRenderer } from "./cell-renderers/getSimpleTypographyCellRenderer";
import { getMoneyTypographyCellRenderer } from "./cell-renderers/getMoneyTypographyCellRenderer";
import { SavingsOpportunityBasedOnPastCellRenderer } from "./cell-renderers/SavingsOpportunityBasedOnPastCellRenderer";
import { SavingsOpportunityCloudProviderCellRenderer } from "./cell-renderers/SavingsOpportunityCloudProviderCellRenderer";
import { getSavingsOpportunitiesInnerHeader } from "./header/SavingsOpportunitiesInnerHeader";
import { SavingsOpportunity } from "../../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../../utils/helpers/resources/getResourceTypeName";
import { agGridEmptyColumnToFitEmptySpace } from "../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import {
  possibleEfforts,
  possibleEffortsLabels,
} from "../utils/constants/common";
import { getProviderName } from "../../../../utils/helpers/providers/getProviderName";

export const savingsOpportunitiesTableColumns: ColDef<SavingsOpportunity>[] = [
  {
    field: "action_type",
    headerName: "Opportunity Type",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    cellRenderer: SavingsOpportunityActionTypeCellRenderer,
  },

  {
    field: "description",
    maxWidth: 400,
    headerName: "Description",
    cellRenderer: getSimpleTypographyCellRenderer("description"),
  },

  {
    field: "account.provider",
    headerName: "Cloud Provider",
    cellRenderer: SavingsOpportunityCloudProviderCellRenderer,
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    getQuickFilterText: ({ data }) => data.account.provider,
    valueGetter: ({ data }) =>
      data?.account?.provider
        ? getProviderName(data.account.provider, { title: true })
        : "",
    comparator: (valueA, valueB, nodeA, nodeB) => {
      const current = nodeA?.data?.account?.provider;
      const next = nodeB?.data?.account?.provider;

      if (!current) return 1;
      if (!next) return -1;

      return current.localeCompare(next);
    },
  },

  {
    field: "service",
    headerName: "Service",
    cellRenderer: SavingsOpportunityServiceCellRenderer,
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    getQuickFilterText: ({ data }) => {
      return getResourceTypeName(data.service, {
        type: "long",
        singular: true,
      });
    },
    valueGetter: ({ data }) => {
      return data?.service
        ? getResourceTypeName(data?.service, {
            type: "long",
            singular: true,
          })
        : "";
    },
  },

  {
    field: "resource_type",
    headerName: "Resource Type",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    cellRenderer: getSimpleTypographyCellRenderer("resource_type"),
  },

  {
    field: "recommended_type",
    headerName: "Recommended Type",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    cellRenderer: getSimpleTypographyCellRenderer("recommended_type"),
  },

  {
    field: "resource_id",
    maxWidth: 400,
    headerName: "Resource ID",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    cellRenderer: getSimpleTypographyCellRenderer("resource_id"),
  },

  {
    field: "resource_arn",
    maxWidth: 400,
    headerName: "Resource ARN",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    cellRenderer: getSimpleTypographyCellRenderer("resource_arn"),
  },

  {
    field: "implementation_effort",
    headerName: "Implementation Effort",
    cellRenderer: SavingsOpportunityImplementationEffortCellRenderer,
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    valueGetter: ({ data }) =>
      data?.implementation_effort
        ? possibleEffortsLabels[data?.implementation_effort]
        : "",
    comparator: (valueA, valueB, nodeA, nodeB) => {
      const current = nodeA?.data?.implementation_effort;
      const next = nodeB?.data?.implementation_effort;

      const currentEffortIndex = possibleEfforts.findIndex(
        (effort) => effort === current,
      );

      const nextEffortIndex = possibleEfforts.findIndex(
        (effort) => effort === next,
      );

      return currentEffortIndex - nextEffortIndex;
    },
  },

  {
    field: "based_on_past",
    headerName: "Based On Past",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    valueGetter: ({ data }) =>
      data?.based_on_past ? `${data?.based_on_past} days` : "",
    cellRenderer: SavingsOpportunityBasedOnPastCellRenderer,
  },

  {
    field: "region",
    headerName: "Region",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    cellRenderer: getSimpleTypographyCellRenderer("region"),
  },

  {
    field: "account.provider_account_id",
    headerName: "Account",
    cellRenderer: SavingsOpportunityAccountCellRenderer,
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    getQuickFilterText: ({ data }) => data?.account?.provider_account_name,
    valueGetter: ({ data }) => data?.account?.provider_account_name,
    comparator: (valueA, valueB, nodeA, nodeB) => {
      const current = nodeA?.data?.account?.provider_account_name;
      const next = nodeB?.data?.account?.provider_account_name;

      if (!current) return 1;
      if (!next) return -1;

      return current.localeCompare(next);
    },
  },

  {
    field: "tags",
    sortable: false,
    filter: false,
    headerName: "Tags",
    cellRenderer: SavingsOpportunityTagsCellRenderer,
    getQuickFilterText: ({ data }) =>
      data.tags?.map((tag) => `${tag.key} ${tag.value}`).join(" ") ?? "",
  },

  {
    field: "estimated_monthly_price",
    filterParams: {
      filters: [
        { filter: "agNumberColumnFilter" },
        { filter: "agSetColumnFilter" },
      ],
    } as IMultiFilterParams,
    headerName: "Estimated Monthly Price",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(true),
    },
    cellRenderer: getMoneyTypographyCellRenderer("estimated_monthly_price"),
  },

  {
    field: "current_monthly_price",
    filterParams: {
      filters: [
        { filter: "agNumberColumnFilter" },
        { filter: "agSetColumnFilter" },
      ],
    } as IMultiFilterParams,
    headerName: "Current Monthly Price",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(true),
    },
    cellRenderer: getMoneyTypographyCellRenderer("current_monthly_price"),
  },

  {
    field: "monthly_savings",
    filterParams: {
      filters: [
        { filter: "agNumberColumnFilter" },
        { filter: "agSetColumnFilter" },
      ],
    } as IMultiFilterParams,
    suppressColumnsToolPanel: true,
    pinned: "right",
    headerName: "Monthly Savings",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(true),
    },
    cellRenderer: SavingsOpportunityMonthlySavingsCellRenderer,
  },

  agGridEmptyColumnToFitEmptySpace,
];
