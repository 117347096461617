import { FC, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { AzureReservationsMetadata } from "./components/metaData/AzureReservationsMetadata";
import { AzureReservationsContent } from "./components/content/AzureReservationsContent";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { commitmentsCurrentOrgIdSelector } from "../../../../../store/commitments/selectors/common/commitmentsCurrentOrgIdSelector";
import { getAzureReservationsThunk } from "../../../../../store/commitments/thunks/azure/reservations/getAzureReservationsThunk";

export const AzureReservations: FC = () => {
  const dispatch = useAppDispatch();

  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);

  useEffect(() => {
    dispatch(getAzureReservationsThunk());
  }, [dispatch, orgId]);

  return (
    <Stack spacing={1}>
      <AzureReservationsMetadata />
      <AzureReservationsContent />
    </Stack>
  );
};
