import { FC, useCallback } from "react";
import { LiveResourceFiltersNavigationItem } from "./LiveResourceFiltersNavigationItem";
import { useAppSelector } from "../../../../../store/hooks";
import { liveUsageMgmtAppliedFiltersByResourceTypeSelector } from "../../../../../store/live-usage-mgmt/selectors/liveUsageMgmtAppliedFiltersByResourceTypeSelector";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";
import { ResourceType } from "../../../../../services/cloudchipr.api";

interface LiveResourceFiltersNavigationItemByResourceTypeProps {
  resourceType: ResourceType;
  active: boolean;
  setActiveResourceType(rt: ResourceType): void;
}

export const LiveResourceFiltersNavigationItemByResourceType: FC<
  LiveResourceFiltersNavigationItemByResourceTypeProps
> = ({ resourceType, active, setActiveResourceType }) => {
  const filter = useAppSelector((state) =>
    liveUsageMgmtAppliedFiltersByResourceTypeSelector(state, resourceType),
  );

  const handleClick = useCallback(() => {
    setActiveResourceType(resourceType);
  }, [resourceType, setActiveResourceType]);

  return (
    <LiveResourceFiltersNavigationItem
      active={active}
      onClick={handleClick}
      title={getResourceTypeName(resourceType, { type: "long" })}
      filtersCount={filter?.filter_items?.length ?? 0}
    />
  );
};
