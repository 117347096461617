import { FC, useCallback } from "react";
import {
  useLocation,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { noOrgAccountOrgId } from "../../../../pages/accounts-group/utils/helpers/noOrganizationConstants";
import { NavItem } from "../../../../navigation/components/nav-item/NavItem";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { liveUsageMgmtSelectedAccountsMetaDataSelector } from "../../../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageMgmtSelectedAccountsMetaDataSelector";
import AWSSvgIcon from "../../../../../assets/images/logos/providers/AWSSvgIcon";
import GCPSvgIcon from "../../../../../assets/images/logos/providers/GCPSvgIcon";
import AzureSvgIcon from "../../../../../assets/images/logos/providers/AzureSvgIcon";
import { useAccountStorageMemoizedTabHook } from "../../../../pages/accounts-group/utils/hooks/useAccountStorageMemoizedTabHook";
import { setAccountsProviderOrganisationId } from "../../../../../store/accounts/accountsSlice";
import { setLiveUsageMgmtProvider } from "../../../../../store/live-usage-mgmt/liveUsageMgmtSlice";
import { useNavigationOverlayContext } from "../../../../navigation/components/nav-item/utils/useNavigationOverlay.context";
import { accountIdsSearchParam } from "../../../../pages/live-usage-mgmt/utils/constants/searchParams";
import { useAppNavOverlayContext } from "../../../utils/useAppNavOverlay.context";

interface LiveUsageNavigationItemProps {
  provider: ProviderType;
  org: string;
  orgId: string;
  accountIdsQueryParam: string;
}

export const LiveUsageNavigationItem: FC<LiveUsageNavigationItemProps> = ({
  provider,
  accountIdsQueryParam,
  org,
  orgId,
}) => {
  const navigate = useNavigate();
  const { onNavigate } = useNavigationOverlayContext();
  const { onClose } = useAppNavOverlayContext();
  const accountsMetaData = useAppSelector(
    liveUsageMgmtSelectedAccountsMetaDataSelector,
  );
  const dispatch = useAppDispatch();
  const { getAccountTab } = useAccountStorageMemoizedTabHook();

  const location = useLocation();

  const activePath = location.pathname;
  const accountsPath = `/${provider}/${orgId}/accounts?accountsType=active`;
  const liveUsagePath = `/${provider}/live-usage/${accountIdsQueryParam}`;
  const accountTab = getAccountTab();
  const path = accountTab === "live-resources" ? liveUsagePath : accountsPath;

  const resolvedPath = useResolvedPath(accountsPath || "");
  const match = useMatch({ path: resolvedPath.pathname });
  const accountsOrLiveUsageActive =
    accountsPath.includes(activePath) || liveUsagePath.includes(activePath);
  const accountsPageActive =
    !!accountsMetaData &&
    accountsPath.includes(
      `/${accountsMetaData?.provider}/${
        accountsMetaData?.groupId ?? noOrgAccountOrgId
      }`,
    );

  const selected =
    (resolvedPath.pathname !== "/" && !!match) ||
    (accountsPageActive && accountsOrLiveUsageActive);

  const setAccountsProviderAndOrganizationId = useCallback(() => {
    dispatch(setAccountsProviderOrganisationId(orgId));
    dispatch(setLiveUsageMgmtProvider(provider));
  }, [dispatch, orgId, provider]);

  const navigateToPath = useCallback(() => {
    navigate(emptyQueryParam === accountIdsQueryParam ? accountsPath : path);
    onNavigate?.();
    onClose?.();
  }, [accountsPath, path, navigate, onNavigate, onClose, accountIdsQueryParam]);

  const handleClick = useCallback(() => {
    navigateToPath();
    setAccountsProviderAndOrganizationId();
  }, [setAccountsProviderAndOrganizationId, navigateToPath]);

  return (
    <NavItem
      nested
      onButtonClick={handleClick}
      key={path}
      primary={org}
      icon={providerIconsMap.get(provider)}
      selected={selected}
    />
  );
};

const emptyQueryParam = `?${accountIdsSearchParam}=`;
const providerIconsMap = new Map<ProviderType, any>([
  ["aws", AWSSvgIcon],
  ["gcp", GCPSvgIcon],
  ["azure", AzureSvgIcon],
]);
