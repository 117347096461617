import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { azureReservationsCoverageDataSelector } from "./azureReservationsCoverageDataSelector";
import {
  AzureCommitmentCoverageData,
  ResourceTypeUsage,
} from "../../../../../../services/cloudchipr.api";
import { ReservationTotalsType } from "../../../../../../components/pages/commtiments/common/utils/types";

export const azureReservationsCoverageDataTotalsSelector =
  createDraftSafeSelector(
    [azureReservationsCoverageDataSelector],
    (data): ReservationTotalsType => {
      return (data as AzureCommitmentCoverageData[])?.reduce(
        (acc, item) => {
          acc.netSavings += item.net_savings ?? 0;
          acc.coveredUsage += item.covered_usage ?? 0;
          acc.unCoveredUsage += item.uncovered_usage ?? 0;
          acc.coveredHours += (item as ResourceTypeUsage)?.covered_units ?? 0;

          return acc;
        },
        { netSavings: 0, coveredUsage: 0, unCoveredUsage: 0, coveredHours: 0 },
      );
    },
  );
