import { Row } from "@tanstack/react-table";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../../store/alerts/utils/types/types";

export const alertsCreatedBySortingFn = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>(
  currentRow: Row<T>,
  nextRow: Row<T>,
) => {
  const currentName = currentRow.original.created_by?.name;
  const nextName = nextRow.original.created_by?.name;

  const currentValue = (
    currentName ??
    currentRow.original.created_by?.email ??
    ""
  ).toLowerCase();
  const nextValue = (
    nextName ??
    nextRow.original.created_by?.email ??
    ""
  ).toLowerCase();

  return currentValue > nextValue ? 1 : -1;
};
