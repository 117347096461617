import { FC } from "react";
import { useLocation } from "react-router-dom";
import { useFlag } from "@unleash/proxy-client-react";
import { AppNavItemWrapper } from "../AppNavItemWrapper";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import ThresholdSvgIcon from "../../../../assets/images/icons/ThresholdSvgIcon";

export const AlertsNavigation: FC = () => {
  const location = useLocation();
  const enableAlerts = useFlag("EnableAlerts");

  const alertsSelected = location.pathname.startsWith("/alerts");

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  if (!enableAlerts) {
    return null;
  }

  return (
    <AppNavItemWrapper
      to="/alerts"
      primary="Budgets & Alerts"
      Icon={ThresholdSvgIcon}
      navCollapsed={navigationCollapsed}
      selected={alertsSelected}
    />
  );
};
