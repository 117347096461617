import { FC } from "react";
import SettingsInputHdmiOutlinedIcon from "@mui/icons-material/SettingsInputHdmiOutlined";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { AppNavItemWrapper } from "../AppNavItemWrapper";

export const IntegrationsNavigation: FC = () => {
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  return (
    <AppNavItemWrapper
      to="/integrations"
      primary="Integrations"
      Icon={SettingsInputHdmiOutlinedIcon}
      navCollapsed={navigationCollapsed}
    />
  );
};
