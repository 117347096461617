import { GetQuickFilterTextParams } from "ag-grid-community";
import { Budget } from "../../../../../../../../services/cloudchipr.api";
import { getUserActivityQuickFilterText } from "../../../../../../reports/components/table/utils/helpers/quick-filters/getUserActivityQuickFilterText";

export const budgetsLastModifiedByQuickFilter = (
  params: GetQuickFilterTextParams<Budget>,
) => {
  const report = params.data;
  const details = report?.modified_by;
  const date = report?.modified_at;

  return getUserActivityQuickFilterText(details, date);
};
