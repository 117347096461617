import { FC } from "react";
import { Stack } from "@mui/material";
import { BudgetsEmptyState } from "./components/BudgetsEmptyState";
import { BudgetsGrid } from "./components/grid/BudgetsGrid";
import { useGetUsersMeOrganisationsCurrentBudgetsQuery } from "../../../services/cloudchipr.api";

export const Budgets: FC = () => {
  const { data, isLoading: loading } =
    useGetUsersMeOrganisationsCurrentBudgetsQuery();

  return (
    <Stack height="100%">
      {!!data?.length && <BudgetsGrid />}

      {!loading && !data?.length && <BudgetsEmptyState />}
    </Stack>
  );
};
