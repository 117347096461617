import { FC } from "react";
import { useLocation } from "react-router-dom";
import { ScheduleSendOutlined } from "@mui/icons-material";
import { useFlag } from "@unleash/proxy-client-react";
import { AppNavItemWrapper } from "../AppNavItemWrapper";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";

export const ReportsNavigation: FC = () => {
  const location = useLocation();
  const enableSubscriptions = useFlag("EnableSubscriptionsPage");

  const reportsSelected = location.pathname.startsWith("/subscriptions");

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  if (!enableSubscriptions) {
    return null;
  }

  return (
    <AppNavItemWrapper
      divider
      to="/subscriptions"
      primary="Subscriptions"
      Icon={ScheduleSendOutlined}
      navCollapsed={navigationCollapsed}
      selected={reportsSelected}
    />
  );
};
