import {
  CommitmentPaymentOption,
  CommitmentResourceType,
  CommitmentState,
  LookbackPeriod,
  ReservationType,
  SavingsPlanAccountScope,
  SavingsPlanCoverageGrouping,
  SavingsPlanTermInYears,
  SavingsPlanType,
} from "../../../../../../services/cloudchipr.api";
import { CommitmentsTabsTypes } from "../../../../../../store/commitments/utils/types/types";

export const commitmentTypesLabels: Record<CommitmentsTabsTypes, string> = {
  savings_plan: "Savings Plan",
  reservation: "Reservation",
  overview: "Overview",
};

export const commitmentsResourceTypeNames: Partial<
  Record<ReservationType | CommitmentResourceType, string>
> = {
  ec2: "EC2",
  rds: "RDS",
  lambda: "Lambda",
  elasticache: "ElastiCache",
  opensearch: "OpenSearch",
  redshift: "Redshift",
  virtual_machines: "Virtual Machines",
  sql_databases: "SQL Database",
  suse_linux: "SUSE Linux",
  cosmos_db: "Cosmos DB",
  red_hat: "Red Hat",
  sql_data_warehouse: "SQL Sata Warehouse",
  vmware_cloud_simple: "VMware Cloud Simple",
  red_hat_osa: "Red Hat OSA",
  databricks: "Databricks",
  app_service: "App Service",
  managed_disk: "Managed Disk",
  block_blob: "Block Blob",
  redis_cache: "Redis Cache",
  azure_data_explorer: "Azure Data Explorer",
  my_sql: "MySQL",
  maria_db: "MariaDB",
  postre_sql: "PostreSQL",
  dedicated_host: "Dedicated Host",
  sap_hana: "SAP HANA",
  sql_azure_hybrid_benefit: "SQL Azure Hybrid Benefit",
  avs: "AVS",
  data_factory: "Data Factory",
  net_app_storage: "NETApp Storage",
  azure_files: "Azure Files",
  sql_edge: "SQL Edge",
  virtual_machine_software: "Virtual machine software",
  fargate: "Fargate",
  sagemaker: "SageMaker",
  eks: "EKS",
};

export const commitmentsPaymentOptionLabels: Record<
  CommitmentPaymentOption,
  string
> = {
  no_upfront: "No Upfront",
  partial_upfront: "Partial Upfront",
  all_upfront: "All Upfront",
  monthly: "Monthly",
  upfront: "Upfront",
};

export const commitmentsStateLabels: Record<CommitmentState, string> = {
  active: "Active",
  expired: "Expired",
  expires_soon: "Expires Soon",
};

export const savingsPlansTypeLabels: Record<SavingsPlanType, string> = {
  sage_maker: "SageMaker",
  compute: "Compute",
  ec2_instance: "EC2",
};

export const coverageGroupingNames: Record<
  SavingsPlanCoverageGrouping,
  string
> = {
  resource_type: "Resources",
  account: "Accounts",
  commitment: "Commitments",
};

export const savingsPlanTermLabels: Record<SavingsPlanTermInYears, string> = {
  one_year: "1-year",
  three_years: "3-years",
};

export const savingsPlanLockBackPeriodLabels: Record<LookbackPeriod, string> = {
  seven_days: "7 days",
  thirty_days: "30 days",
  sixty_days: "60 days",
};

export const savingsPlanLockBackPeriodDays: Record<LookbackPeriod, number> = {
  seven_days: 7,
  thirty_days: 30,
  sixty_days: 60,
};

export const savingsPlanAccountScopeLabels: Record<
  SavingsPlanAccountScope,
  string
> = {
  payer: "Payer",
  linked: "Linked",
};
