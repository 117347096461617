import { MetricsFrequenciesKeysType } from "../types/metricsFrequenciesType";
import { MetricType } from "../../cells/metrics-cells/utils/types";

export const expandableResourceTypes = new Set([
  "asg",
  "node_group",
  "node_pool",
  "az_node_pool",
  "eks",
  "mig",
  "rds_snapshot_source",
  "az_vmss",
  "gke",
  "az_aks",
  "ecs",
]);

export const getMetricsTypeAndOptionById = new Map<
  MetricsFrequenciesKeysType,
  {
    type: MetricType;
    option: "sum" | "max" | "min";
  }
>([
  ["cpu_max", { type: "compute", option: "max" }],
  ["memory_max", { type: "memory_used", option: "max" }],
  ["unused_memory", { type: "memory_available", option: "min" }],
  ["total_connection_sum", { type: "database", option: "sum" }],
  ["all_request_sum", { type: "storage", option: "sum" }],
  ["processed_bytes", { type: "processed_bytes", option: "sum" }],
]);
