import { DashboardFolderItemNameCell } from "./cells/DashboardFolderItemNameCell";
import { ColumnSetupType } from "../../../../../../storybook/data-grid/utils/types/types";
import { DashboardMixedVisibilitiesItemType } from "../utils/types/types";
import { isHierarchyFolder } from "../../../../../../store/navigation/utils/helpers/isHierarchyFolder";
import { hierarchyAllItemsNameFilter } from "../../../../common/hierarchy-all-items/helpers/filterFunctions/hierarchyAllItemsNameFilter";
import { hierarchyAllItemsVisibilityFilter } from "../../../../common/hierarchy-all-items/helpers/filterFunctions/hierarchyAllItemsVisibilityFilter";
import { UserActivityCell } from "../../../../../common/data-grid-cells/UserActivityCell";
import { DashboardV2FolderActionButton } from "../../../../../navigation/components/dashboard-v2/components/folder-action-menu/DashboardV2FolderActionButton";
import { DashboardV2ItemActionButton } from "../../../../../navigation/components/dashboard-v2/components/item-action-menu/DashboardV2ItemActionButton";

import { hierarchyAllItemsModifyByFilter } from "../../../../common/hierarchy-all-items/helpers/filterFunctions/hierarchyAllItemsModfiedByFilter";
import { hierarchyAllItemsCreatedByFilter } from "../../../../common/hierarchy-all-items/helpers/filterFunctions/hierarchyAllItemsCreatedByFilter";
import { hierarchyAllItemsCreatedBySortingFn } from "../../../../common/hierarchy-all-items/helpers/sortingFuntions/hierarchyAllItemsCreatedBySortingFn";
import { HierarchyAllItemsVisibilityCell } from "../../../../common/hierarchy-all-items/components/table-cells/HierarchyAllItemsVisibilityCell";

export const dashboardAllItemsTableColumns: ColumnSetupType<DashboardMixedVisibilitiesItemType>[] =
  [
    {
      header: "Name",
      accessorKey: "name",
      minSize: 100,
      size: 250,
      maxSize: 450,
      cell: (cell) => {
        const item = cell.row.original;
        const toggleExpandedHandler = cell.row.getToggleExpandedHandler();
        const expanded = cell.row.getIsExpanded();

        return (
          <DashboardFolderItemNameCell
            id={item.id}
            name={item.name}
            expanded={expanded}
            visibility={item.visibility}
            folder={isHierarchyFolder(item)}
            toggleExpandedHandler={toggleExpandedHandler}
            isProtected={
              !isHierarchyFolder(item) &&
              !!item.protection_details?.is_protected
            }
          />
        );
      },
      filterFn: hierarchyAllItemsNameFilter,
      meta: {
        hideFromSettings: true,
      },
    },
    {
      header: "Visibility",
      accessorKey: "visibility",
      minSize: 70,
      size: 150,
      maxSize: 250,
      cell: (cell) => {
        const visibility = cell.row.original.visibility;

        return <HierarchyAllItemsVisibilityCell visibility={visibility} />;
      },
      filterFn: hierarchyAllItemsVisibilityFilter,
    },
    {
      header: "Created By",
      accessorKey: "id",
      minSize: 180,
      size: 180,
      maxSize: 250,
      cell: (cell) => {
        const view = cell.row.original;
        const createdBy = view.created_by;

        return (
          <UserActivityCell
            date={view.created_at}
            name={createdBy?.name ?? createdBy?.email ?? ""}
            deleted={createdBy?.status === "deleted"}
          />
        );
      },
      filterFn: hierarchyAllItemsCreatedByFilter,
      sortingFn: hierarchyAllItemsCreatedBySortingFn,
    },
    {
      header: "Last Modified By",
      accessorKey: "modified_at",
      minSize: 180,
      size: 180,
      maxSize: 250,
      cell: (cell) => {
        const date = cell.row.original.modified_at;
        const modified_by = cell.row.original.modified_by;

        if (!date) {
          return "-";
        }

        return (
          <UserActivityCell
            date={date}
            name={modified_by?.name ?? modified_by?.email ?? ""}
            deleted={modified_by?.status === "deleted"}
          />
        );
      },
      filterFn: hierarchyAllItemsModifyByFilter,
    },
    {
      id: "actions",
      header: "",
      minSize: 60,
      size: 70,
      maxSize: 80,
      enableResizing: false,
      enableGlobalFilter: false,
      cell: (cell) => {
        const data = cell.row.original;

        if (isHierarchyFolder(data)) {
          return (
            <DashboardV2FolderActionButton
              size="medium"
              id={data.id}
              name={data.name}
              redirectAfterCreate={false}
              visibility={data.visibility}
              containsProtectedItem={data.items?.some(
                (item) => item.protection_details?.is_protected,
              )}
            />
          );
        }

        return (
          <DashboardV2ItemActionButton
            size="medium"
            id={data.id}
            name={data.name}
            visibility={data.visibility}
            protectionDetails={data.protection_details}
            protectedBy={data.protected_by}
          />
        );
      },
      meta: {
        hideFromSettings: true,
        cellStyles: {
          textAlign: "right",
        },
      },
    },
  ];
