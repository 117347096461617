import { ResourceExplorerGroupingNullable } from "../../../../../../../../services/cloudchipr.api";

export type NextGroupingByCurrentGroupingType = Record<
  Exclude<ResourceExplorerGroupingNullable, null>,
  ResourceExplorerGroupingNullable
>;

export const nextGroupingByCurrentGrouping: NextGroupingByCurrentGroupingType =
  {
    cloud_provider: "account",
    charge_type: "account",
    region: "resource",
    account: "service",
    environment: "service",
    service: "resource",
    resource: "product_family",
    product_family: "resource",
    instance_family: "instance_type",
    instance_type: "resource",
    cost_allocation_tag: "cost_allocation_tag_value",
    tag_key: "cost_allocation_tag_value",
    cost_allocation_tag_value: "resource",
    tag_value: "resource",
    none: "cloud_provider",
    category: "account",
    marketplace_service: "resource",
    resource_group: "resource",

    // EKS
    namespace: "workload_name",
    type: "workload_name",
    deployment: "workload_name",
    workload_name: "type",
    workload_type: "workload_name",
    parent_resource_id: "type",
    cluster: "namespace",
    node: "type",

    pricing_term: "resource",
    description: "resource",
    usage_type: "resource",

    // MONGO
    organisation: "group",
    group: "cluster",

    credit_type: null,
  };
