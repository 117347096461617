import { useCallback } from "react";
import { VisibilityState } from "@tanstack/react-table";
import { AlertTabType } from "../../../../../../../store/alerts/utils/types/types";
import { useLocalStorageHook } from "../../../../../../../utils/hooks/useLocalStorage.hook";

export function useAlertsColumnsVisibility(alertType: AlertTabType) {
  const { getItem, setItem } = useLocalStorageHook();

  const key = `c8r:${alertType}-data-grid-columns-visibility`;

  const visibility = (getItem(key) ?? {}) as Record<string, boolean>;

  const setVisibility = useCallback(
    (visibilities: VisibilityState) => {
      setItem(key, visibilities);
    },
    [key, setItem],
  );

  return {
    visibility,
    setVisibility,
  };
}
