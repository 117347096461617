import { FC } from "react";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ICellRendererParams } from "ag-grid-community";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { Budget } from "../../../../../../../services/cloudchipr.api";

export const BudgetsViewCell: FC<ICellRendererParams<Budget>> = ({
  data: budget,
}) => {
  const id = budget?.view.id;
  const name = budget?.view.name;
  if (!id || !name) {
    return;
  }

  return (
    <Link
      component={RouterLink}
      to={`/resource-explorer/${id}`}
      color="primary"
      display="block"
      width="fit-content"
    >
      <TypographyWithTooltip maxWidth={200} variant="body1" title={name} />
    </Link>
  );
};
