import { ResourceExplorerFilterProvider } from "../../../../../../services/cloudchipr.api";
import { includeAllExcludedProvider } from "../constants/common";
import {
  IncludeAllCloudProviders,
  IncludeAllFilterType,
} from "../types/common";

export const isIncludeAllAvailableProvider = (
  provider: ResourceExplorerFilterProvider,
): provider is IncludeAllCloudProviders => {
  return !includeAllExcludedProvider.includes(provider);
};

export const isIncludeAllFilterType = (
  filter: any,
): filter is IncludeAllFilterType => {
  return !!filter?.provider;
};
