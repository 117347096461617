import { FC } from "react";
import { useLocation } from "react-router-dom";
import AvTimerOutlinedIcon from "@mui/icons-material/AvTimerOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import { AppNavItemWrapper } from "../AppNavItemWrapper";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { useAppSelector } from "../../../../store/hooks";
import { AppNavItem } from "../AppNavItem";

export const AutomationsNavigation: FC = () => {
  const location = useLocation();
  const selected = location.pathname.startsWith("/automations");

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  return (
    <AppNavItemWrapper
      primary="Automations"
      to="/automations/workflow"
      Icon={AvTimerOutlinedIcon}
      selected={selected}
      navCollapsed={navigationCollapsed}
    >
      <AppNavItem
        nested
        primary="Workflows"
        to="/automations/workflow"
        icon={AccountTreeOutlinedIcon}
        selected={location.pathname.startsWith("/automations/workflow")}
      />

      <AppNavItem
        nested
        primary="Off Hours"
        to="/automations/off-hours"
        icon={HistoryToggleOffOutlinedIcon}
        selected={location.pathname.startsWith("/automations/off-hours")}
      />
    </AppNavItemWrapper>
  );
};
