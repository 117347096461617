import { TabList } from "@mui/lab";
import { Box, Divider, LinearProgress, Stack, Tab } from "@mui/material";
import { FC, SyntheticEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { BudgetsAndAlertsTabLabel } from "./BudgetsAndAlertsTabLabel";
import { AlertTabType } from "../../../../store/alerts/utils/types/types";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setAlertsActiveTab } from "../../../../store/alerts/alertsSlice";
import { costAnomalyAlertsLengthSelector } from "../../../../store/alerts/selectors/cost-anomaly-alerts/costAnomalyAlertsLengthSelector";
import { utilizationAlertsLengthSelector } from "../../../../store/alerts/selectors/utilization-alerts/utilizationAlertsLengthSelector";
import { alertsActiveTabSelector } from "../../../../store/alerts/selectors/alertsActiveTabSelector";
import { currentBudgetsLengthSelector } from "../../../../store/budgets/selectors/currentBudgetsLengthSelector";
import { alertsPageDataLoadingSelector } from "../../../../store/alerts/selectors/alertsPageDataLoadingSelector";
import { budgetsDataLoadingSelector } from "../../../../store/budgets/selectors/current-budget/data-selectors/budgetsDataLoadingSelector";

export const BudgetsAndAlertsTabs: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const costAnomalyLength = useAppSelector(costAnomalyAlertsLengthSelector);
  const utilizationLength = useAppSelector(utilizationAlertsLengthSelector);
  const budgetLength = useAppSelector(currentBudgetsLengthSelector);
  const alertsActiveTab = useAppSelector(alertsActiveTabSelector);
  const alertsLoading = useAppSelector(alertsPageDataLoadingSelector);
  const budgetsLoading = useAppSelector(budgetsDataLoadingSelector);

  const loading = alertsLoading || budgetsLoading;

  const handleTabChange = useCallback(
    (_: SyntheticEvent, tab: AlertTabType) => {
      dispatch(setAlertsActiveTab(tab));
      navigate(`/alerts?alertType=${tab}`, { replace: true });
    },
    [dispatch, navigate],
  );

  return (
    <Stack position="relative">
      <TabList sx={{ bgcolor: "white" }} onChange={handleTabChange}>
        <Tab
          value="budget"
          label={
            <BudgetsAndAlertsTabLabel
              label="Budgets"
              count={budgetLength}
              active={alertsActiveTab === "budget"}
            />
          }
          sx={{ textTransform: "none" }}
        />
        <Tab
          value="costAnomaly"
          label={
            <BudgetsAndAlertsTabLabel
              label="Cost Anomaly Alerts"
              count={costAnomalyLength}
              active={alertsActiveTab === "costAnomaly"}
            />
          }
          sx={{ textTransform: "none" }}
        />
        <Tab
          value="utilization"
          label={
            <BudgetsAndAlertsTabLabel
              label="Commitment Alerts"
              count={utilizationLength}
              active={alertsActiveTab === "utilization"}
            />
          }
          sx={{ textTransform: "none" }}
        />
      </TabList>

      {loading && (
        <Box position="absolute" bottom={0} left={0} right={0}>
          <LinearProgress />
        </Box>
      )}

      <Divider />
    </Stack>
  );
};
