import { FC, Fragment, useEffect, useMemo } from "react";
import { Card, CircularProgress, Container, Grid, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useWillUnmount } from "rooks";
import { BackButton } from "./components/budget-create/BackButton";
import { BudgetsCreateSteps } from "./components/budget-create/steps/BudgetsCreateSteps";
import { BudgetsCreateChart } from "./components/budget-create/BudgetsCreateChart";
import { PageHeader } from "../common/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  initiateBudgetForCreate,
  resetCurrentBudget,
} from "../../../store/budgets/budgetsSlice";
import { initiateBudgetByIdThunk } from "../../../store/budgets/thunks/initiateBudgetByIdThunk";
import { resourceExplorerViewsLoadingSelector } from "../../../store/resource-explorer/selectors/all-resource-explorers/resourceExplorerViewsLoadingSelector";
import { currentBudgetDataLoadingSelector } from "../../../store/budgets/selectors/current-budget/currentBudgetDataLoadingSelector";

export const BudgetItem: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const viewsLoading = useAppSelector(resourceExplorerViewsLoadingSelector);
  const currentBudgetLoading = useAppSelector(currentBudgetDataLoadingSelector);

  const breadCrumbs = useMemo(() => {
    return [
      { title: "Budgets", to: "/alerts" },
      { title: id ? "Edit Budget" : "Create New Budget" },
    ];
  }, [id]);

  useEffect(() => {
    if (id) {
      dispatch(initiateBudgetByIdThunk(id));
    } else {
      dispatch(initiateBudgetForCreate());
    }
  }, [dispatch, id]);

  useWillUnmount(() => {
    dispatch(resetCurrentBudget());
  });

  return (
    <Fragment>
      <PageHeader title="Budgets" breadcrumbs={breadCrumbs} />

      <Container sx={{ p: 2 }} disableGutters>
        <BackButton />

        <Card variant="outlined" sx={{ p: 2, minHeight: 650 }}>
          {viewsLoading || currentBudgetLoading ? (
            <Stack alignItems="center" justifyContent="center" minHeight={450}>
              <CircularProgress />
            </Stack>
          ) : (
            <Grid container>
              <Grid item xs={12} lg={6}>
                <BudgetsCreateSteps />
              </Grid>
              <Grid item xs={12} lg={6}>
                <BudgetsCreateChart />
              </Grid>
            </Grid>
          )}
        </Card>
      </Container>
    </Fragment>
  );
};
