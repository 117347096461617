import { Row } from "@tanstack/react-table";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../../store/alerts/utils/types/types";
import { formatDate } from "../../../../../../../../utils/helpers/date-time/datetime-format";

export const alertsCreatedByFilter = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>(
  row: Row<T>,
  _: string,
  keyword: string,
) => {
  const data = row.original;
  const filterValue = keyword.toLowerCase().trim();
  const filterData =
    `${formatDate(data.created_at ?? "", { type: "dateTime" })} ${data.created_by?.email} ${data.created_by?.name}`
      .toLowerCase()
      .trim();

  return filterData.includes(filterValue);
};
