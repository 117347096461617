import { Dispatch, FC, SetStateAction } from "react";
import { FilterChangedEvent, GridApi } from "ag-grid-community";
import { BudgetsGridNoRowsComponent } from "./components/BudgetsGridNoRowsComponent";
import { budgetsGridColumns } from "./utils/helpers/budgetsGridColumns";
import { Budget } from "../../../../../services/cloudchipr.api";
import { AgDataGrid } from "../../../common/ag-grid/AgDataGrid";

interface BudgetsDataGridProps {
  data: Budget[];
  setGridApi: Dispatch<SetStateAction<GridApi | null>>;
  onFilterChanged(event: FilterChangedEvent): void;
}

export const BudgetsDataGrid: FC<BudgetsDataGridProps> = ({
  data,
  setGridApi,
  onFilterChanged,
}) => {
  return (
    <AgDataGrid
      rowHeight={58}
      headerHeight={56}
      rowData={data}
      columnDefs={budgetsGridColumns}
      defaultColDef={defaultColDef}
      onFilterChanged={onFilterChanged}
      gridApiSetter={setGridApi}
      noRowsOverlayComponent={BudgetsGridNoRowsComponent}
      configToRememberStateInStorage={configToRememberStateInStorage}
    />
  );
};

const defaultColDef = {
  unSortIcon: true,
  minWidth: 150,
};

const configToRememberStateInStorage = {
  uniqueNameAsKey: "budgetsDataGrid",
};
