import { FC } from "react";
import { useLocation } from "react-router-dom";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import { LiveUsageNavigationItem } from "./LiveUsageNavigationItem";
import { AppNavItemWrapper } from "../../AppNavItemWrapper";
import { useAppSelector } from "../../../../../store/hooks";
import { liveUsageNavigationItemsSelector } from "../../../../../store/accounts/selectors/liveUsageNavigationItemsSelector";
import { navigationCollapsedSelector } from "../../../../../store/common/selectors/navigation/navigationCollapsedSelector";

export const LiveUsageNavigation: FC = () => {
  const allAccountsGroupedByProviderAndOrg = useAppSelector(
    liveUsageNavigationItemsSelector,
  );
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);
  const location = useLocation();
  const selected = ["/aws", "/gcp", "/azure"].some((item) =>
    location.pathname.startsWith(item),
  );

  const firstItem = allAccountsGroupedByProviderAndOrg.at(0);

  if (!firstItem) {
    return null;
  }

  return (
    <AppNavItemWrapper
      divider
      to={`${firstItem.provider}/${firstItem.orgId}/accounts?accountsType=active`}
      primary="Live Resources"
      Icon={PlayCircleOutlineOutlinedIcon}
      selected={selected}
      navCollapsed={navigationCollapsed}
    >
      {allAccountsGroupedByProviderAndOrg.map((props) => (
        <LiveUsageNavigationItem
          {...props}
          key={props.orgId + props.provider + props.accountIdsQueryParam}
        />
      ))}
    </AppNavItemWrapper>
  );
};
