import { FC, useCallback } from "react";
import { HierarchyViewSelectionWrapper } from "../../../../../common/hierarchy-view-selection/HierarchyViewSelectionWrapper";
import { AlertsHierarchySelectTrigger } from "../../../../../budgets-and-alerts/components/drawer/components/cost-anomaly/components/AlertsHierarchySelectTrigger";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { reportsDataPropertyByKeySelector } from "../../../../../../../store/reports/selector/reportsDataPropertyByKeySelector";
import { setReportsData } from "../../../../../../../store/reports/reportsSlice";

export const ReportsDrawerViewSelect: FC = () => {
  const dispatch = useAppDispatch();
  const viewId = useAppSelector(reportsDataPropertyByKeySelector("source_id"));

  const handleChange = useCallback(
    (viewId: string) => {
      dispatch(
        setReportsData({
          source_id: viewId,
        }),
      );
    },
    [dispatch],
  );

  return (
    <HierarchyViewSelectionWrapper
      selectedItemId={viewId ?? ""}
      onChange={handleChange}
      CustomTriggerComponent={AlertsHierarchySelectTrigger}
    />
  );
};
