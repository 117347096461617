import { FC } from "react";
import { Stack } from "@mui/material";
import { alertsUtilizationDataGridColumns } from "./alertsUtilizationDataGridColumns";
import { AlertsEmptyPage } from "../common/AlertsEmptyPage";
import { useAppSelector } from "../../../../../../store/hooks";
import { getUtilizationAlertsLoadingSelector } from "../../../../../../store/alerts/selectors/utilization-alerts/getUtilizationAlertsLoadingSelector";
import { utilizationAlertsDataGridDataSelector } from "../../../../../../store/alerts/selectors/utilization-alerts/utilizationAlertsDataGridDataSelector";
import { DataGrid } from "../../../../../../storybook/data-grid/DataGrid";
import { AlertsDataGridToolbar } from "../common/AlertsDataGridToolbar";
import { ToolbarConfig } from "../../../../../../storybook/data-grid/utils/types/prop-types";
import { NoFilteredData } from "../../../../../common/NoFilteredData";
import { alertsTableStyles } from "../common/utils/constants/alertsTableStyles";
import { useAlertsColumnsVisibility } from "../common/hooks/useAlertsColumnsVisibility.hook";
import { alertsActiveTabSelector } from "../../../../../../store/alerts/selectors/alertsActiveTabSelector";

export const AlertsUtilization: FC = () => {
  const data = useAppSelector(utilizationAlertsDataGridDataSelector);
  const emptyData = !data?.length;
  const utilizationLoading = useAppSelector(
    getUtilizationAlertsLoadingSelector,
  );
  const alertsActiveTab = useAppSelector(alertsActiveTabSelector);
  const { visibility, setVisibility } =
    useAlertsColumnsVisibility(alertsActiveTab);

  return (
    <Stack pb={5} flexGrow={1} bgcolor="white">
      {emptyData && !utilizationLoading && <AlertsEmptyPage />}

      {!emptyData && (
        <DataGrid
          globalFilter
          data={data}
          styles={alertsTableStyles}
          columnVisibility={{
            initialVisibility: visibility,
            onColumnsVisibilityChange: setVisibility,
          }}
          columnResizeMode="onEnd"
          toolbar={dataGridToolbar}
          columnSorting={sortingConfig}
          enableStickyColumns={!!data.length}
          columns={alertsUtilizationDataGridColumns}
          Footer={NoFilteredData}
        />
      )}
    </Stack>
  );
};

const sortingConfig = { initialSort: [{ id: "name", desc: false }] };

const dataGridToolbar: ToolbarConfig = {
  renderToolbar: AlertsDataGridToolbar,
};
