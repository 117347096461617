import { FC, useCallback, useMemo } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { capitalizeString } from "../../../../../../../utils/helpers/capitalizeString";
import { setCurrentBudgetFilters } from "../../../../../../../store/budgets/budgetsSlice";
import { DropdownSelect } from "../../../../../../common/select/dropdown-select/DropdownSelect";
import { budgetsAppliedPeriodsFiltersSelector } from "../../../../../../../store/budgets/selectors/current-budget/filters/applied/budgetsAppliedPeriodsFiltersSelector";

export const BudgetPeriodsFilter: FC = () => {
  const dispatch = useAppDispatch();
  const initialPeriods = useAppSelector(budgetsAppliedPeriodsFiltersSelector);

  const options = useMemo(
    () =>
      periods.map((value) => ({
        value: value,
        hideOnly: true,
        label: capitalizeString(value),
      })),
    [],
  );

  const optionsCount = options.length;
  const changeHandler = useCallback(
    (newFilters: string[]) => {
      dispatch(
        setCurrentBudgetFilters({
          key: "periods",
          values: newFilters.length === optionsCount ? [] : newFilters,
        }),
      );
    },
    [dispatch, optionsCount],
  );

  return (
    <DropdownSelect
      initialSelectedValues={initialPeriods}
      label="Periods"
      options={options}
      submitHandlerOnClose={changeHandler}
    />
  );
};

const periods = ["daily", "monthly", "quarterly", "annually"];
