import { FC, ReactNode, useCallback, useMemo, useRef, useState } from "react";
import {
  Box,
  IconButton,
  Popper,
  Collapse,
  useTheme,
  ListItemTextProps,
  ListItemProps,
  ListItemIcon,
  ListItemText,
  ListItem,
  ListItemButton,
  Tooltip,
  Divider,
} from "@mui/material";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { SvgIconComponent } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { useAppSelector } from "../../../store/hooks";
import { isSubscriptionDisabledSelector } from "../../../store/subscriptions/subscriptionsSelectors";
import { TypographyWithTooltip } from "../../common/TypographyWithTooltip";
import { navbarCollapsedDefaultWidth } from "../../layout/utils/hooks/useLayoutConfigHook";
import { AppNavOverlayContext } from "../utils/useAppNavOverlay.context";

interface AppNavItemProps {
  to: string;
  selected?: boolean;
  children?: ReactNode;
  navCollapsed?: boolean;
  Icon: SvgIconComponent;
  primary: ListItemTextProps["primary"];
  secondaryAction?: ListItemProps["secondaryAction"];
  divider?: boolean;
  disabled?: boolean;
}

export const AppNavItemWrapper: FC<AppNavItemProps> = ({
  to,
  primary,
  children,
  selected,
  secondaryAction,
  navCollapsed,
  Icon,
  divider,
  disabled: disabledByProps,
}) => {
  const { zIndex } = useTheme();
  const ref = useRef<HTMLElement | null>(null);

  const resolvedPath = useResolvedPath(to || "");
  const match = useMatch({ path: resolvedPath.pathname });
  selected = (resolvedPath.pathname !== "/" && !!match) || selected;
  const disabled =
    useAppSelector(isSubscriptionDisabledSelector) || disabledByProps;

  const [hovered, setHovered] = useState(false);

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const context = useMemo(() => {
    return { onClose: onMouseLeave };
  }, [onMouseLeave]);

  return (
    <Box
      ref={ref}
      textAlign="center"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {navCollapsed ? (
        <Tooltip title={primary} placement="right" arrow>
          <IconButton
            to={to}
            onClick={onMouseLeave}
            component={NavLink}
            sx={{
              width: navbarCollapsedDefaultWidth,
              height: navbarCollapsedDefaultWidth,
              borderRadius: 0,
              opacity: disabled ? 0.4 : undefined,
              bgcolor: selected ? "primary.light" : "default",
            }}
            disabled={disabled}
          >
            <Icon fontSize="small" color={selected ? "primary" : undefined} />
          </IconButton>
        </Tooltip>
      ) : (
        <ListItem sx={{ p: 0 }} secondaryAction={secondaryAction}>
          <ListItemButton
            to={to}
            onClick={onMouseLeave}
            component={NavLink}
            selected={selected}
            disabled={disabled}
            sx={{ p: 1.25, pr: 3 }}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <Icon fontSize="small" color={selected ? "primary" : undefined} />
            </ListItemIcon>
            <ListItemText
              primary={
                <TypographyWithTooltip
                  title={primary}
                  variant="inherit"
                  fontWeight={selected ? "medium" : "normal"}
                  color={selected ? "primary" : undefined}
                />
              }
            />
          </ListItemButton>
        </ListItem>
      )}

      {children && !disabled && (
        <Popper
          open={hovered}
          anchorEl={ref.current}
          sx={{ transform: "unset !important", zIndex: zIndex.drawer }}
        >
          <Box
            sx={popperContentStyles}
            left={ref?.current?.clientWidth ?? 0}
            borderLeft={`1px solid ${grey[300]}`}
          >
            <ListItem
              sx={{ px: 1, py: 0.25 }}
              secondaryAction={secondaryAction}
            >
              <ListItemIcon sx={{ minWidth: 30 }}>
                <Icon fontSize="small" />
              </ListItemIcon>

              <ListItemText
                primary={
                  <TypographyWithTooltip
                    pr={5}
                    title={primary}
                    variant="inherit"
                  />
                }
              />
            </ListItem>

            <AppNavOverlayContext.Provider value={context}>
              <Collapse in>{children}</Collapse>
            </AppNavOverlayContext.Provider>
          </Box>
        </Popper>
      )}

      {divider && <Divider />}
    </Box>
  );
};

const popperContentStyles = {
  top: 0,
  pt: 1.25,
  width: 340,
  height: "100vh",
  bgcolor: "white",
  overflow: "auto",
  position: "fixed",
  boxShadow: "8px 0px 16px 0px rgba(0, 0, 0, 0.1)",
};
