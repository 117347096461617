import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { costBreakdownGroupingChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/setup-change/costBreakdownGroupingChangeThunk";
import { ResourceExplorerGroupingNullable } from "../../../../../../../../../../services/cloudchipr.api";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { GroupingSelectorWrapper } from "../../../../../../../../common/resource-explorer-grouping-selector/GroupingSelectorWrapper";

interface CostBreakdownWidgetSetupGroupingSelectProps {
  disabled?: boolean;
}

export const CostBreakdownWidgetSetupGroupingSelect: FC<
  CostBreakdownWidgetSetupGroupingSelectProps
> = ({ disabled }) => {
  const dispatch = useAppDispatch();

  const groupBy = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("grouping"),
  );
  const groupValues = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("group_values"),
  );

  const groupingChangeHandler = useCallback(
    (grouping: ResourceExplorerGroupingNullable, groupValues?: string[]) => {
      dispatch(
        costBreakdownGroupingChangeThunk({
          grouping,
          groupValues,
        }),
      );
    },
    [dispatch],
  );

  return (
    <GroupingSelectorWrapper
      value={groupBy}
      disabled={disabled}
      groupValues={groupValues}
      hiddenGroupings={["resource"]}
      onChange={groupingChangeHandler}
    />
  );
};
