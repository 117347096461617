import { FC, Fragment, useMemo } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
import { SerializedError } from "@reduxjs/toolkit";
import { Stack, Tooltip } from "@mui/material";
import { useDidMount } from "rooks";
import {
  DashboardWidgetLargestCostChanges,
  GetUsersMeOrganisationsCurrentWidgetsLargestCostChangesByWidgetIdApiResponse,
} from "../../../../../services/cloudchipr.api";
import { UnauthorizedWidgetHeader } from "../UnauthorizedWidgetHeader";
import CompareIcon from "../../../../../assets/images/icons/compare.svg";
import { LargestCostChangesPrevDateDetails } from "../../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/larges-cost-changes/content/components/LargestCostChangesPrevDateDetails";
import { DashboardNotFoundWidget } from "../../../../pages/dashboard/components/widgets/common/DashboardNotFoundWidget";
import { LargestCostChangesWidgetBody } from "../../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/larges-cost-changes/content/components/LargestCostChangesWidgetBody";
import { LargestCostChangesWidgetFooter } from "../../../../pages/dashboard/components/widgets/largest-cost-change/components/footer/LargestCostChangesWidgetFooter";
import { generateDatesCompareData } from "../../../../common/date-range-picker/utils/helpers/date-formatter/generateDatesCompareData";

interface UnauthorizedLargestCostChangesWidgetProps
  extends DashboardWidgetLargestCostChanges {
  data?: GetUsersMeOrganisationsCurrentWidgetsLargestCostChangesByWidgetIdApiResponse;
  isError: boolean;
  error?: FetchBaseQueryError | SerializedError;
  onWidgetMount?(): void;
  organisationId?: string;
}

export const UnauthorizedLargestCostChangesWidget: FC<
  UnauthorizedLargestCostChangesWidgetProps
> = ({
  data,
  error,
  isError,
  dates,
  name,
  grouping,
  onWidgetMount,
  organisationId,
}) => {
  const status = error && "status" in error ? error?.status : 0;
  const prevDate = data?.previous_period_cost_date_details;

  const dateInfo = useMemo(() => {
    return generateDatesCompareData({
      prevDate: prevDate,
      date: dates,
    });
  }, [prevDate, dates]);

  useDidMount(() => {
    onWidgetMount?.();
  });

  if (isError && status !== 404) {
    return;
  }

  return (
    <Stack flexGrow={1} overflow="auto">
      <UnauthorizedWidgetHeader
        name={name}
        grouping={grouping}
        dates={dates}
        organisationId={organisationId}
      >
        <Tooltip
          title={dateInfo.title}
          arrow
          placement="top"
          componentsProps={{ tooltip: { sx: { maxWidth: 630 } } }}
        >
          <Stack alignItems="center">
            <img src={CompareIcon} alt="compare" />
          </Stack>
        </Tooltip>
        <LargestCostChangesPrevDateDetails
          prevDates={{
            ...dates,
            from: prevDate?.from_date,
            to: prevDate?.to_date,
          }}
        />
      </UnauthorizedWidgetHeader>

      {status === 404 ? (
        <DashboardNotFoundWidget name="" label="billing report" />
      ) : (
        <Fragment>
          <LargestCostChangesWidgetBody
            date={dates}
            loading={false}
            data={data?.total ?? []}
            previousPeriodDate={prevDate}
          />

          <LargestCostChangesWidgetFooter prevDate={prevDate} date={dates} />
        </Fragment>
      )}
    </Stack>
  );
};
