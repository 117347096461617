import { FC, useCallback } from "react";
import { LiveResourceFiltersNavigationItem } from "./LiveResourceFiltersNavigationItem";
import { allResourcesResourceType } from "../../../../pages/live-usage-mgmt/utils/constants/common";
import { useAppSelector } from "../../../../../store/hooks";
import { liveUsageMgmtAppliedAllFiltersCountSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAppliedAllFiltersCountSelector";
import { LiveUsageNavigationResourceType } from "../../../../pages/live-usage-mgmt/components/navigation/utils/hooks/useLiveUsageMgmtPageNavigate.hook";

interface LiveResourceFiltersNavigationAllResourcesItemProps {
  activeResourceType: LiveUsageNavigationResourceType;
  setActiveResourceType(rt: LiveUsageNavigationResourceType): void;
}

export const LiveResourceFiltersNavigationAllResourcesItem: FC<
  LiveResourceFiltersNavigationAllResourcesItemProps
> = ({ setActiveResourceType, activeResourceType }) => {
  const allResourcesCount = useAppSelector(
    liveUsageMgmtAppliedAllFiltersCountSelector,
  );

  const allResourcesClickHandler = useCallback(() => {
    setActiveResourceType(allResourcesResourceType);
  }, [setActiveResourceType]);

  return (
    <LiveResourceFiltersNavigationItem
      title="All Filters"
      onClick={allResourcesClickHandler}
      filtersCount={allResourcesCount}
      active={activeResourceType === allResourcesResourceType}
    />
  );
};
