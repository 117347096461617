import { useMemo } from "react";
import { ListItemText, Stack } from "@mui/material";
import { useResourceExplorerDimensionsOptions } from "./useResourceExplorerDimensionsOptions";
import { ResourceExplorerGroupingNullable } from "../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../store/hooks";
import { connectedProvidersSelector } from "../../../../../../store/common/selectors/connectedProvidersSelector";
import { resourceExplorerGroupedPossibleGroupingsSelector } from "../../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupedPossibleGroupingsSelector";
import { resourceExplorerGroupingsParentLabelsSelector } from "../../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupingsParentLabelsSelector";
import { GroupingOptionIcon } from "../GroupingOptionIcon";
import { GroupingAvailableProviders } from "../GroupingAvailableProviders";
import { DropdownSelectOption } from "../../../../../common/select/dropdown-select/utils/types/types";
import { resourceExplorerHasNoParentKey } from "../../utils/constants/constants";
import { GroupingByTagValueOption } from "../GroupingByTagValueOption";
import { getResourceExplorerGroupingOptionsWithParentLabel } from "../getResourceExplorerGroupingOptionsWithParentLabel";

interface UseResourceExplorerGroupingOptions {
  groupValues?: string[];
  value: ResourceExplorerGroupingNullable;
  hiddenGroupings?: ResourceExplorerGroupingNullable[];
  onChange(
    grouping: ResourceExplorerGroupingNullable,
    groupValue?: string[],
  ): void;
  closeHandler(): void;
}

export const useResourceExplorerGroupingRenderOptions = ({
  hiddenGroupings,
  value,
  groupValues,
  onChange,
  closeHandler,
}: UseResourceExplorerGroupingOptions) => {
  const providers = useAppSelector(connectedProvidersSelector);
  const dimensionsOptions = useResourceExplorerDimensionsOptions();
  const groupedPossibleGroupings = useAppSelector(
    resourceExplorerGroupedPossibleGroupingsSelector,
  );
  const parentLabels = useAppSelector(
    resourceExplorerGroupingsParentLabelsSelector,
  );

  return useMemo(() => {
    if (!groupedPossibleGroupings || !parentLabels) {
      return [];
    }

    return parentLabels?.reduce((result, parentLabel) => {
      if (parentLabel === resourceExplorerHasNoParentKey) {
        groupedPossibleGroupings?.[parentLabel]
          ?.filter((item) => !hiddenGroupings?.includes(item.key))
          ?.map((grouping) => {
            const label = grouping?.items[0]?.label?.singular;
            const groupingKey = grouping.key;
            if (groupingKey === "tag_key") {
              result.push({
                value: groupingKey,
                disableSelection: true,
                rawValue: { search: `${groupingKey} ${label}` },
                label: (
                  <GroupingByTagValueOption
                    onChange={onChange}
                    onClose={closeHandler}
                    values={value === groupingKey ? groupValues : []}
                    label={label ?? ""}
                  />
                ),
              } as DropdownSelectOption);
            } else {
              result.push({
                value: groupingKey,
                rawValue: { search: `${groupingKey} ${label}` },
                label: (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <GroupingOptionIcon grouping={groupingKey} />
                    <ListItemText
                      primary={label}
                      primaryTypographyProps={{
                        sx: { whiteSpace: "nowrap", width: "fit-content" },
                      }}
                      sx={{ flexGrow: 0 }}
                    />
                    <GroupingAvailableProviders grouping={groupingKey} />
                  </Stack>
                ),
              } as DropdownSelectOption);
            }
          });
      } else if (
        groupedPossibleGroupings?.[parentLabel][0].key === "category" &&
        dimensionsOptions
      ) {
        result.push(dimensionsOptions);
      } else {
        const children = groupedPossibleGroupings?.[parentLabel]?.map(
          (groupingData) => {
            return groupingData?.items[0];
          },
        );
        result.push(
          getResourceExplorerGroupingOptionsWithParentLabel({
            parentLabel,
            children,
          }),
        );
      }
      return result;
    }, [] as any[]);
  }, [
    dimensionsOptions,
    closeHandler,
    value,
    groupValues,
    onChange,
    providers,
    groupedPossibleGroupings,
    parentLabels,
  ]);
};
