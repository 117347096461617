import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { resourceExplorerGroupingSelector } from "../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { resourceExplorerGroupValuesSelector } from "../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupValuesSelector";
import { resourceExplorerGroupByChangeThunk } from "../../../../../../../../store/resource-explorer/thunks/resource-explorer/resourceExplorerGroupByChangeThunk";
import { ResourceExplorerGroupingNullable } from "../../../../../../../../services/cloudchipr.api";
import { GroupingSelectorWrapper } from "../../../../../../common/resource-explorer-grouping-selector/GroupingSelectorWrapper";

export const ResourceExplorerGroupBySelector: FC = () => {
  const dispatch = useAppDispatch();

  const groupBy = useAppSelector(resourceExplorerGroupingSelector);
  const groupValues = useAppSelector(resourceExplorerGroupValuesSelector);

  const groupingChangeHandler = useCallback(
    (
      newGroupBy: ResourceExplorerGroupingNullable,
      newGroupValues?: string[],
    ) => {
      const groupByIsNotChanged = groupBy === newGroupBy;
      const valuesAreTheSame =
        groupValues?.join("") === newGroupValues?.join("");

      if (groupByIsNotChanged && (!newGroupValues || valuesAreTheSame)) {
        return;
      }

      dispatch(
        resourceExplorerGroupByChangeThunk({
          grouping: newGroupBy,
          groupValues: newGroupValues,
        }),
      );
    },
    [dispatch, groupBy, groupValues],
  );

  return (
    <GroupingSelectorWrapper
      value={groupBy}
      triggerSize="xsmall"
      dropDownMaxHeight={600}
      groupValues={groupValues}
      onChange={groupingChangeHandler}
    />
  );
};
