import { Stack } from "@mui/material";
import { FC } from "react";
import { LiveUsageMgmtResourcesCount } from "./LiveUsageMgmtResourcesCount";
import { ResourceType } from "../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { isLiveUsageMgmtFilterAppliedToResourceSelector } from "../../../../../../../../../../store/live-usage-mgmt/selectors/isLiveUsageMgmtFilterAppliedToResourceSelector";
import { liveUsageMgmtResourceViewIsWithoutFiltersSelector } from "../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceViewIsWithoutFiltersSelector";
import { liveUsageMgmtResourceTypeDataTotalCountSelector } from "../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/count/liveUsageMgmtResourceTypeDataTotalCountSelector";
import { liveUsageMgmtFilteredResourcesChildResourcesCountSelector } from "../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/count/liveUsageMgmtFilteredResourcesChildResourcesCountSelector";
import { liveUsageMgmtResourceTypeDataLoadingSelector } from "../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataLoadingSelector";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../../../../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";
import { liveUsageMgmtFilteredResourcesCountSelector } from "../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/count/liveUsageMgmtFilteredResourcesCountSelector";
import { LiveUsageResourceFilter } from "../../../../../../filters/LiveUsageResourceFilter";

interface ResourceCardFilterContentProps {
  resourceType: ResourceType;
}
export const LiveUsageMgmtResourceCardFilterContent: FC<
  ResourceCardFilterContentProps
> = ({ resourceType }) => {
  const filtersApplied = useAppSelector((state) =>
    isLiveUsageMgmtFilterAppliedToResourceSelector(state, resourceType),
  );

  const viewIsWithoutFilters = useAppSelector((state) =>
    liveUsageMgmtResourceViewIsWithoutFiltersSelector(state, resourceType),
  );

  const isLoading = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataLoadingSelector(state, resourceType),
  );

  const resourcesTotalCount = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataTotalCountSelector(state, resourceType),
  );
  const resourcesCount = useAppSelector((state) =>
    liveUsageMgmtFilteredResourcesCountSelector(state, resourceType),
  );
  const resourceChildrenCount = useAppSelector((state) =>
    liveUsageMgmtFilteredResourcesChildResourcesCountSelector(
      state,
      resourceType,
    ),
  );

  const filterTemplatesEnabled = useAppSelector(
    liveUsageMgmtFilterTemplatesEnabledSelector,
  );

  const count =
    resourceType === "rds_snapshot_source"
      ? resourceChildrenCount
      : resourcesCount;

  return (
    <Stack direction="row" spacing={0.5}>
      {!viewIsWithoutFilters && filterTemplatesEnabled && (
        <LiveUsageResourceFilter resourceType={resourceType} />
      )}

      <LiveUsageMgmtResourcesCount
        resourceType={resourceType}
        dataCount={count}
        isLoading={isLoading}
        totalDataCount={resourcesTotalCount}
        filtersApplied={filtersApplied}
      />
    </Stack>
  );
};
