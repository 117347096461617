import { FC, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import MenuList from "@mui/material/MenuList";
import {
  FilterItemSelectMenuItem,
  NewFilterAddFn,
} from "./FilterItemSelectMenuItem";
import { IncludeAllByCloudProviderFilterItem } from "./IncludeAllByCloudProviderFilterItem";
import {
  ResourceExplorerFilterProvider,
  ResourceExplorerPossibleFilterV2,
} from "../../../../../../../services/cloudchipr.api";
import {
  isIncludeAllAvailableProvider,
  isIncludeAllFilterType,
} from "../../../utils/helpers/isIncludeAllAvailableProvider";
import { ModifiedFilterType } from "../../../utils/types/common";

interface FilterItemSelectProps {
  filters: ResourceExplorerPossibleFilterV2[];
  onSelect: NewFilterAddFn;
}

export const FilterItemSelect: FC<FilterItemSelectProps> = ({
  filters,
  onSelect,
}) => {
  const modifiedFilters = useMemo(() => {
    let prevCloudProvider: ResourceExplorerFilterProvider | undefined;

    return filters.reduce((acc, filter) => {
      if (prevCloudProvider !== filter.filter_provider) {
        if (
          !filter?.filter_provider ||
          !isIncludeAllAvailableProvider(filter?.filter_provider)
        ) {
          return acc;
        }

        acc.push({ provider: filter.filter_provider });
        prevCloudProvider = filter.filter_provider;
      } else {
        acc.push(filter);
      }

      return acc;
    }, [] as ModifiedFilterType);
  }, [filters]);

  return (
    <Stack
      p={1}
      width={360}
      maxHeight={filters.length ? 350 : undefined}
      overflow="auto"
    >
      {filters.length ? (
        <MenuList disablePadding>
          {modifiedFilters.map((filter) =>
            isIncludeAllFilterType(filter) ? (
              <IncludeAllByCloudProviderFilterItem
                key={`includeAll-${filter.provider}`}
                provider={filter.provider}
                onSelect={onSelect}
              />
            ) : (
              <FilterItemSelectMenuItem
                key={`menuItem-${filter.key}-${filter.filter_provider}`}
                filter={filter}
                onSelect={onSelect}
              />
            ),
          )}
        </MenuList>
      ) : (
        <Typography variant="caption" align="center" p={2}>
          No options found.
        </Typography>
      )}
    </Stack>
  );
};
