import { getDimensionGroupingOptions } from "../specific-options/getDimensionGroupingOptions";
import { useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery } from "../../../../../../services/cloudchipr.api";
import { DropdownSelectOption } from "../../../../../common/select/dropdown-select/utils/types/types";

export const useResourceExplorerDimensionsOptions =
  (): DropdownSelectOption | null => {
    const { data: dimensionsOptions } = useValuesQuery({
      filterProvider: "dimensions",
      filterType: "dimension_id",
    });

    const dimensionGroupingOptions =
      getDimensionGroupingOptions(dimensionsOptions);
    return dimensionGroupingOptions ?? null;
  };
