import { FC, useCallback, useMemo } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { NewFilterAddFn } from "./FilterItemSelectMenuItem";
import { useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery } from "../../../../../../../services/cloudchipr.api";
import { ProviderIcon } from "../../../../../../common/ProviderIcon";
import { getProviderName } from "../../../../../../../utils/helpers/providers/getProviderName";
import { IncludeAllCloudProviders } from "../../../utils/types/common";

interface IncludeAllByCloudProviderFilterItemProps {
  provider: IncludeAllCloudProviders;
  onSelect: NewFilterAddFn;
}

export const IncludeAllByCloudProviderFilterItem: FC<
  IncludeAllByCloudProviderFilterItemProps
> = ({ provider, onSelect }) => {
  const { data, isLoading } = useValuesQuery({
    filterProvider: provider,
    filterType: "account",
  });

  const value = useMemo(() => data?.map((option) => option.value), [data]);

  const clickHandler = useCallback(() => {
    if (!value) {
      return;
    }

    onSelect(provider, "account", "in", value);
  }, [onSelect, provider, value]);

  return (
    <MenuItem sx={menuItemSx} disabled={isLoading} onClick={clickHandler}>
      <ListItemIcon>
        <ProviderIcon provider={provider} />
      </ListItemIcon>

      <ListItemText
        primary={`Include All ${getProviderName(provider, { title: true })}`}
      />
    </MenuItem>
  );
};

const menuItemSx = {
  px: 0.5,
  my: 1,
  borderRadius: 2,
  "&.Mui-selected": { color: "primary.main" },
};
