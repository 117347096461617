import { FC } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { NavItem } from "../nav-item/NavItem";
import ThresholdSvgIcon from "../../../../assets/images/icons/ThresholdSvgIcon";

export const AlertsNavigation: FC = () => {
  const location = useLocation();

  const alertsSelected = location.pathname.startsWith("/alerts");

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  return (
    <NavItem
      to="/alerts"
      primary="Budgets & Alerts"
      icon={ThresholdSvgIcon}
      navCollapsed={navigationCollapsed}
      selected={alertsSelected}
    />
  );
};
