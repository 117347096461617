import { FC, Fragment } from "react";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ICellRendererParams } from "ag-grid-community";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { useAppAbility } from "../../../../../../../services/permissions";
import { Budget } from "../../../../../../../services/cloudchipr.api";

export const BudgetsNameCell: FC<ICellRendererParams<Budget>> = ({
  data: budget,
}) => {
  const { can } = useAppAbility();
  const canEditBudget = can("edit", "budget");

  const id = budget?.id;
  const name = budget?.name;
  if (!id || !name) {
    return;
  }

  return (
    <Fragment>
      {canEditBudget ? (
        <Link
          component={RouterLink}
          to={`/budgets/edit/${id}`}
          color="primary"
          display="block"
          width="fit-content"
        >
          <TypographyWithTooltip maxWidth={200} variant="body1" title={name} />
        </Link>
      ) : (
        <TypographyWithTooltip maxWidth={200} variant="body1" title={name} />
      )}
    </Fragment>
  );
};
