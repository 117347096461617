import { FC } from "react";
import ListIcon from "@mui/icons-material/List";
import { AppNavItemWrapper } from "../AppNavItemWrapper";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";

export const DimensionsNavigation: FC = () => {
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  return (
    <AppNavItemWrapper
      to="/dimensions"
      primary="Dimensions"
      Icon={ListIcon}
      navCollapsed={navigationCollapsed}
    />
  );
};
