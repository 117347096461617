import { createAsyncThunk } from "@reduxjs/toolkit";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";
import { ResourceExplorerGroupingNullable } from "../../../../../../services/cloudchipr.api";
import { costBreakdownWidgetSetupSelector } from "../../../../selectors/setups/cost-breakdown/costBreakdownWidgetSetupSelector";
import { getCostBreakdownWidgetSetupDataThunk } from "../getCostBreakdownWidgetSetupDataThunk";

interface CostBreakdownGroupingChangeThunkArgs {
  grouping: ResourceExplorerGroupingNullable;
  groupValues?: string[];
}
export const costBreakdownGroupingChangeThunk = createAsyncThunk(
  "dashboards/costBreakdownGroupingChange",
  async (
    { grouping, groupValues }: CostBreakdownGroupingChangeThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const existingSetup = costBreakdownWidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }
    dispatch(
      setWidgetSetup({
        ...existingSetup,
        grouping,
        group_values: groupValues ?? null,
      }),
    );
    dispatch(getCostBreakdownWidgetSetupDataThunk());
  },
);
