import { FC } from "react";
import { useLocation } from "react-router-dom";
import { useFlag } from "@unleash/proxy-client-react";
import { DatasetLinkedOutlined } from "@mui/icons-material";
import { NavItem } from "../nav-item/NavItem";
import {
  ProviderType,
  useGetUsersMeOrganisationsCurrentProviderOrganisationsQuery,
} from "../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import AWSSvgIcon from "../../../../assets/images/logos/providers/AWSSvgIcon";
import GCPSvgIcon from "../../../../assets/images/logos/providers/GCPSvgIcon";
import AzureSvgIcon from "../../../../assets/images/logos/providers/AzureSvgIcon";
import { getProviderName } from "../../../../utils/helpers/providers/getProviderName";
import { noOrgProviderOrganisationOrgId } from "../../../../store/commitments/selectors/organisations/utils/constants";

export const CommitmentsNavigation: FC = () => {
  const enableReservedInstances = useFlag("EnableReservedInstances");
  const enableAzureCommitments = useFlag("EnableAzureCommitments");

  const { data } = useGetUsersMeOrganisationsCurrentProviderOrganisationsQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        return {
          data: data?.filter((org) => {
            if (enableAzureCommitments && org?.has_savings_plan) {
              return org.cloud_provider !== "gcp";
            }
            return org.cloud_provider === "aws";
          }),
        };
      },
    },
  );

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  const location = useLocation();
  const selected = location.pathname.startsWith("/commitments");
  const disabled = !data?.length;

  if (!data) {
    return null;
  }

  if ((data?.length ?? 0) < 2) {
    const firstItem = data.at(0);
    const orgId = firstItem?.organization_id ?? "";
    const provider = firstItem?.cloud_provider ?? "";

    return (
      <NavItem
        primary="Commitments"
        icon={DatasetLinkedOutlined}
        selected={selected}
        navCollapsed={navigationCollapsed}
        disabled={disabled}
        to={
          enableReservedInstances
            ? `/commitments/${provider}/${orgId.length ? orgId : noOrgProviderOrganisationOrgId}`
            : `/commitments/utilization-and-coverage/${orgId.length ? orgId : noOrgProviderOrganisationOrgId}`
        }
      />
    );
  }

  return (
    <NavItem
      primary="Commitments"
      icon={DatasetLinkedOutlined}
      navCollapsed={navigationCollapsed}
      selected={selected}
    >
      {data?.map((org) => (
        <NavItem
          nested
          key={org.organization_id}
          primary={
            org.organization_id ??
            getProviderName(org.cloud_provider, {
              capitalize: true,
              title: true,
            })
          }
          icon={providerIconsMap.get(org.cloud_provider)}
          to={
            enableReservedInstances
              ? `/commitments/${org.cloud_provider}/${org.organization_id ?? noOrgProviderOrganisationOrgId}`
              : `/commitments/utilization-and-coverage/${org.organization_id ?? noOrgProviderOrganisationOrgId}`
          }
        />
      ))}
    </NavItem>
  );
};

const providerIconsMap = new Map<ProviderType, any>([
  ["aws", AWSSvgIcon],
  ["gcp", GCPSvgIcon],
  ["azure", AzureSvgIcon],
]);
