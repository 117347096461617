import { FC } from "react";
import { Avatar, AvatarGroup, Tooltip } from "@mui/material";
import { ProviderIcon } from "../../../../common/ProviderIcon";
import { getCloudProvidersGroupTooltipTitle } from "../utils/helpers/getCloudProvidersGroupTooltipTitle";
import { ResourceExplorerGroupingNullable } from "../../../../../services/cloudchipr.api";
import { availableCloudProvidersByGrouping } from "../utils/constants/constants";

interface GroupingAvailableProvidersProps {
  grouping: ResourceExplorerGroupingNullable;
  size?: "medium" | "small";
}

export const GroupingAvailableProviders: FC<
  GroupingAvailableProvidersProps
> = ({ grouping, size = "medium" }) => {
  const cloudProviders = availableCloudProvidersByGrouping(grouping);

  const title = getCloudProvidersGroupTooltipTitle(grouping, cloudProviders);
  const { avatarSize, iconSize, spacing } = sizes[size];

  return (
    <Tooltip arrow placement="top" title={title}>
      <AvatarGroup spacing={spacing} sx={avatarGroupStyles}>
        {cloudProviders?.map((provider) => {
          return (
            <Avatar
              key={provider}
              sx={{ bgcolor: "white", height: avatarSize, width: avatarSize }}
            >
              <ProviderIcon
                provider={provider}
                key={provider}
                width={iconSize}
              />
            </Avatar>
          );
        })}
      </AvatarGroup>
    </Tooltip>
  );
};

const sizes: Record<
  "medium" | "small",
  { avatarSize: number; iconSize: number; spacing: number }
> = {
  medium: {
    avatarSize: 28,
    iconSize: 16,
    spacing: 12,
  },
  small: {
    avatarSize: 23,
    iconSize: 13,
    spacing: 10,
  },
};

const avatarGroupStyles = {
  "& >.MuiAvatar-root": {
    border: 1,
    borderColor: "grey.200",
  },
};
