import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { costBreakdownWidgetSetupTotalDataCountSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/setup/costBreakdownWidgetSetupTotalDataCountSelector";
import { resourceExplorerGroupingLabelByGroupingSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupingLabelByGroupingSelector";

export const CostBreakdownWidgetSetupTableLabelHeaderCell: FC = () => {
  const dataCount = useAppSelector((state) =>
    costBreakdownWidgetSetupTotalDataCountSelector(state),
  );
  const grouping = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("grouping"),
  );

  const groupingLabel = useAppSelector((state) =>
    resourceExplorerGroupingLabelByGroupingSelector(state, grouping),
  );

  if (!grouping) {
    return null;
  }

  return (
    <Stack pl={1}>
      {groupingLabel?.singular}
      <Typography variant="tiny" color="text.disabled" fontWeight="medium">
        {dataCount}
      </Typography>
    </Stack>
  );
};
