import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ResourceExplorerInterface,
  ResourceExplorerPath,
} from "./utils/types/resourceExplorer";
import { ResourceExplorerDataGridView } from "./utils/types/resourceExplorerDataGrid";
import {
  DateDataPoint,
  ForecastOption,
  Dates,
  ResourceExplorerData,
  TrendType,
  CostType,
  ResourceExplorerGroupingNullable,
} from "../../services/cloudchipr.api";
import { ResourceExplorerChartBase } from "../../components/pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-chart/utils/types";
import { FilterTreeNodeWithId } from "../../components/pages/common/filters-tree/utils/types/common";

const initialState: ResourceExplorerInterface = {
  id: "",
  data: null,
  filterTree: undefined,
  path: [],
  search: "",
  quarterEndForecastVisibility: false,
  dataGridView: null,
  csvDataHash: "",
  chartBase: "cost",
};

export const resourceExplorerSlice = createSlice({
  name: "resourceExplorer",
  initialState: initialState,
  reducers: {
    setResourceExplorerId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },

    setResourceExplorerSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setResourceExplorerDataGridView: (
      state,
      action: PayloadAction<ResourceExplorerDataGridView>,
    ) => {
      state.dataGridView = action.payload;
    },
    setQuarterEndForecastVisibility: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.quarterEndForecastVisibility = action.payload;
    },
    setResourceExplorerData: (
      state,
      action: PayloadAction<ResourceExplorerData>,
    ) => {
      const { date_label, from_date, to_date } = action.payload;

      state.data = {
        ...action.payload,
        from_date: date_label && from_date ? null : from_date,
        to_date: date_label && to_date ? null : to_date,
      };
    },
    setResourceExplorerGrouping: (
      state,
      action: PayloadAction<{
        grouping: ResourceExplorerGroupingNullable;
        groupValues?: string[];
      }>,
    ) => {
      if (!state.data) {
        return;
      }

      state.data.grouping = action.payload.grouping;
      state.data.group_values = action.payload.groupValues;
    },
    setResourceExplorerDataPoint: (
      state,
      action: PayloadAction<DateDataPoint>,
    ) => {
      if (!state.data) {
        return;
      }

      state.data.data_point = action.payload;
    },
    setResourceExplorerDates: (state, action: PayloadAction<Dates>) => {
      if (!state.data) {
        return;
      }

      state.data.dates = action.payload;
    },
    setResourceExplorerForecastOption: (
      state,
      action: PayloadAction<ForecastOption>,
    ) => {
      if (!state.data) {
        return;
      }

      state.data.forecast_option = action.payload;
    },
    resetResourceExplorer: () => {
      return initialState;
    },

    setResourceExplorerPath: (
      state,
      action: PayloadAction<ResourceExplorerPath[]>,
    ) => {
      state.path = action.payload;
    },
    toggleShowBudgetLine: (state) => {
      if (!state.data) {
        return;
      }

      state.data.show_budget_line = !state.data.show_budget_line;
    },
    setResourceExplorerCsvDataHash: (state, action: PayloadAction<string>) => {
      state.csvDataHash = action.payload;
    },
    setResourceExplorerTrendType: (state, action: PayloadAction<TrendType>) => {
      if (!state.data) {
        return;
      }

      state.data.trend_type = action.payload;
    },
    setResourceExplorerCostType: (state, action: PayloadAction<CostType>) => {
      if (!state.data) {
        return;
      }

      state.data.cost_type = action.payload;
    },
    setResourceExplorerChartBase: (
      state,
      action: PayloadAction<ResourceExplorerChartBase>,
    ) => {
      state.chartBase = action.payload;
    },
    setResourceExplorerFilterTree: (
      state,
      action: PayloadAction<FilterTreeNodeWithId | undefined>,
    ) => {
      state.filterTree = action.payload;
    },
  },
});

export const {
  setResourceExplorerPath,
  resetResourceExplorer,
  setResourceExplorerData,
  setResourceExplorerDataPoint,
  setResourceExplorerGrouping,
  setResourceExplorerDates,
  setResourceExplorerId,
  setResourceExplorerSearch,
  setResourceExplorerForecastOption,
  setQuarterEndForecastVisibility,
  setResourceExplorerDataGridView,
  toggleShowBudgetLine,
  setResourceExplorerCsvDataHash,
  setResourceExplorerTrendType,
  setResourceExplorerCostType,
  setResourceExplorerChartBase,
  setResourceExplorerFilterTree,
} = resourceExplorerSlice.actions;

export default resourceExplorerSlice.reducer;
