import { FC, useCallback, useMemo } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { DropdownSelect } from "../../../../../../common/select/dropdown-select/DropdownSelect";
import { setCurrentBudgetFilters } from "../../../../../../../store/budgets/budgetsSlice";
import { budgetsAppliedReportsFiltersSelector } from "../../../../../../../store/budgets/selectors/current-budget/filters/applied/budgetsAppliedReportsFiltersSelector";
import { resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector } from "../../../../../../../store/resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector";
import { resourceExplorerHierarchyVisibilitiesDataLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesDataLoadingSelector";

export const BudgetReportsFilter: FC = () => {
  const dispatch = useAppDispatch();

  const reports = useAppSelector(
    resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector,
  );
  const optionsLoading = useAppSelector(
    resourceExplorerHierarchyVisibilitiesDataLoadingSelector,
  );

  const initialReports = useAppSelector(budgetsAppliedReportsFiltersSelector);

  const options = useMemo(
    () =>
      reports?.map((value) => ({
        value: value.name,
        hideOnly: true,
        label: value.name,
      })),
    [reports],
  );

  const optionsCount = options?.length;
  const changeHandler = useCallback(
    (newFilters: string[]) => {
      dispatch(
        setCurrentBudgetFilters({
          key: "reports",
          values: newFilters.length === optionsCount ? [] : newFilters,
        }),
      );
    },
    [dispatch, optionsCount],
  );

  return (
    <DropdownSelect
      initialSelectedValues={initialReports}
      label="Reports"
      options={options ?? []}
      optionsLoading={optionsLoading}
      submitHandlerOnClose={changeHandler}
    />
  );
};
