import { FC } from "react";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import { useFlag } from "@unleash/proxy-client-react";
import { useLocation } from "react-router-dom";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import PhotoSizeSelectSmallOutlinedIcon from "@mui/icons-material/PhotoSizeSelectSmallOutlined";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { useAppSelector } from "../../../../store/hooks";
import { AppNavItemWrapper } from "../AppNavItemWrapper";
import { AppNavItem } from "../AppNavItem";

export const SavingsOpportunitiesNavigation: FC = () => {
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);
  const enableSavingsOpportunities = useFlag("EnableSavingsOpportunities");

  const location = useLocation();
  const selected = location.pathname.startsWith("/recommendations");

  return (
    <AppNavItemWrapper
      primary="Savings Opportunities"
      to="/recommendations/off-hours"
      Icon={LightbulbOutlinedIcon}
      selected={selected}
      navCollapsed={navigationCollapsed}
    >
      <AppNavItem
        nested
        to="/recommendations/off-hours"
        primary="Off Hours"
        icon={HistoryToggleOffOutlinedIcon}
      />
      <AppNavItem
        nested
        to="/recommendations/rightsizing"
        primary="Rightsizing"
        icon={PhotoSizeSelectSmallOutlinedIcon}
      />

      {enableSavingsOpportunities && (
        <AppNavItem
          nested
          to="/recommendations/savings-opportunities"
          primary="All Opportunities"
          icon={SelectAllIcon}
        />
      )}
    </AppNavItemWrapper>
  );
};
