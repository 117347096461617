import { FC, Fragment, useMemo } from "react";
import { Stack, TextField } from "@mui/material";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { GroupingOptionIcon } from "../../../pages/common/resource-explorer-grouping-selector/components/GroupingOptionIcon";
import { CountChipWithTooltip } from "../../../pages/common/resource-explorer-grouping-selector/components/CountChipWithTooltip";
import { useGetResourceExplorerFiltersByFilterProviderAndFilterTypeValuesFromAccountServiceQuery } from "../../../../services/cloudchipr-account-service.api";
import { GroupingAvailableProviders } from "../../../pages/common/resource-explorer-grouping-selector/components/GroupingAvailableProviders";
import { ResourceExplorerGroupingNullable } from "../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../store/hooks";
import { resourceExplorerInternalGroupingLabelByGroupingSelector } from "../../../../store/resource-explorer/selectors/internal/resourceExplorerInternalGroupingLabelByGroupingSelector";
import { resourceExplorerInternalPossibleGroupingKeysSelector } from "../../../../store/resource-explorer/selectors/internal/resourceExplorerInternalPossibleGroupingKeysSelector";
import { resourceExplorerInternalPossibleGroupingsLoadingSelector } from "../../../../store/resource-explorer/selectors/internal/resourceExplorerInternalPossibleGroupingsLoadingSelector";

interface InternalGroupBySelectorTriggerProps {
  grouping: ResourceExplorerGroupingNullable;
  groupingValues?: string[];
  organisationId: string;
}

export const InternalGroupBySelectorSkeleton: FC<
  InternalGroupBySelectorTriggerProps
> = ({ grouping, organisationId, groupingValues }) => {
  const resourceExplorerAllGroupings = useAppSelector((state) =>
    resourceExplorerInternalPossibleGroupingKeysSelector(state, organisationId),
  );

  const possibleGroupingLoading = useAppSelector((state) =>
    resourceExplorerInternalPossibleGroupingsLoadingSelector(
      state,
      organisationId,
    ),
  );
  const groupBy =
    grouping && resourceExplorerAllGroupings?.includes(grouping)
      ? grouping
      : "category";

  const groupingLabel = useAppSelector((state) =>
    resourceExplorerInternalGroupingLabelByGroupingSelector(
      state,
      organisationId,
      groupBy,
    ),
  );

  const groupingSingularLabel = groupingLabel?.singular ?? "";
  const { data } =
    useGetResourceExplorerFiltersByFilterProviderAndFilterTypeValuesFromAccountServiceQuery(
      {
        filterProvider: "dimensions",
        filterType: "dimension_id",
        organisationId,
      },
      { skip: groupBy !== "category" },
    );

  const dimensionName = useMemo(() => {
    if (groupBy !== "category") {
      return "";
    }

    const firstValue = groupingValues?.at(0);
    const selected = data?.find((option) => option.value === firstValue);

    return selected?.title ?? selected?.value ?? "";
  }, [groupingValues, groupBy, data]);

  const label = groupBy === "category" ? dimensionName : groupingSingularLabel;

  return (
    <TextField
      size="xsmall"
      label="Group by"
      contentEditable={false}
      disabled={possibleGroupingLoading}
      value={grouping ? label : ""}
      InputProps={{
        readOnly: true,
        inputProps: {
          style: {
            paddingLeft: 8,
            cursor: "pointer",
            caretColor: "transparent",
            textOverflow: "ellipsis",
            overflow: "hidden",
          },
        },
        sx: { pr: 1, cursor: "pointer", minWidth: 175 },
        endAdornment: (
          <TriggerEndAdornment
            grouping={grouping}
            values={
              groupBy === "cost_allocation_tag" ? groupingValues : undefined
            }
          />
        ),
        startAdornment: grouping ? (
          <GroupingOptionIcon grouping={groupBy} />
        ) : undefined,
      }}
    />
  );
};

const TriggerEndAdornment: FC<{
  values?: string[];
  grouping: ResourceExplorerGroupingNullable;
}> = ({ values, grouping }) => {
  return (
    <Fragment>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <GroupingAvailableProviders size="small" grouping={grouping} />

        {!!values?.length && <CountChipWithTooltip values={values} />}
      </Stack>

      <ArrowDropDownOutlinedIcon color="action" />
    </Fragment>
  );
};
