import { FC } from "react";
import { Box } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { AzureReservedInstancesDataGrid } from "./AzureReservedInstancesDataGrid";
import { AzureReservationsCoverageDataGrid } from "./AzureReservationsCoverageDataGrid";
import { AzureReservationsDataGridViewTabs } from "./components/AzureReservationsDataGridViewTabs";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { commitmentsDataViewTabSelector } from "../../../../../../../../store/commitments/selectors/common/view-options/commitmentsDataViewTabSelector";
import { azureReservationsForTableLoadingSelector } from "../../../../../../../../store/commitments/selectors/azure/reservations/data/loading/azureReservationsForTableLoadingSelector";
import { ReservationsRecommendationsDataGrid } from "../../../../../reservations/components/content/recomendations/ReservationsRecommendationsDataGrid";
import { CommitmentsGridLoadingLayer } from "../../../../../common/components/data-grid/CommitmentsGridLoadingLayer";
import { ToolbarConfig } from "../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { CommitmentsDataGridToolbar } from "../../../../../common/components/data-grid/CommitmentsDataGridToolbar";
import { ReservationsRecommendationsDataGridToolbar } from "../../../../../reservations/components/content/recomendations/data-grid/ReservationsRecommendationsDataGridToolbar";

export const AzureReservationsDataGrid: FC = () => {
  const tab = useAppSelector(commitmentsDataViewTabSelector);
  const loading = useAppSelector(azureReservationsForTableLoadingSelector);

  const enableReservationRecommendation = useFlag(
    "EnableReservationRecommendation",
  );
  if (tab === "recommendations") {
    if (enableReservationRecommendation) {
      return (
        <ReservationsRecommendationsDataGrid toolbar={recommendationsToolbar} />
      );
    }
    return null;
  }

  return (
    <Box position="relative" zIndex={1}>
      {loading && <CommitmentsGridLoadingLayer />}
      {tab === "commitment" && (
        <AzureReservedInstancesDataGrid toolbar={toolbar} />
      )}
      {tab === "coverage" && (
        <AzureReservationsCoverageDataGrid toolbar={toolbar} />
      )}
    </Box>
  );
};

const toolbar: ToolbarConfig = {
  renderToolbar: (props) => {
    return (
      <CommitmentsDataGridToolbar {...props}>
        <AzureReservationsDataGridViewTabs />
      </CommitmentsDataGridToolbar>
    );
  },
};
const recommendationsToolbar: ToolbarConfig = {
  renderToolbar: (props) => (
    <ReservationsRecommendationsDataGridToolbar {...props} />
  ),
};
